import { Component } from "react";

import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import { MatchInfo } from "../../../types/entities/match-info";
import { Player } from "../../../types/entities/player";
import { Squad } from "../../../types/entities/squad";
import { MatchRole } from "../../../types/enums/match-role";
import { StatisticType } from "../../../types/enums/statistic-type";
import { services } from "../../../types/services";
import { MatchStatsWrapper } from "../../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../../types/stats/player-stats";
import { UUID } from "../../../types/uuid";
import PlayerSelectorGlobalBiasesComponent from "../../entity-management/entity-selectors/player-selector-global-biases-component";
import { TeamPlayerSelector } from "../../entity-management/entity-selectors/team-player-selector";
import { PlayerView } from "../../my-matches/match-creation-modals/player-view";
import { getTitleColour } from "../game-state-display/tsbc";

import { RetireModal } from "./retire-modal";

interface Props {
  gameState: GameState;
  matchInfo: MatchInfo;
  squad: Squad;
  selectedBowler: UUID;
  matchFormat: MatchFormat;
  primaryColour: string;
  secondaryColour: string;
  matchStats: MatchStatsWrapper;
  playerStats: Map<string, PlayerStatsWrapper>;
  onModalOpen: () => void;
  onModalClose: () => void;
  onSpinnerEdit: (spinnerBeingEdited: boolean) => void;
  focussed: boolean;
}

interface State {
  retireModalOpen: boolean;
}

export class BowlerSelector extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      retireModalOpen: false,
    };
  }

  private selectBowler(player: Player) {
    services.currentGameService.selectBowler(
      player === null ? null : player.playerId
    );
  }

  private getUnavailableBowlers(): UUID[] {
    return this.props.squad && this.props.squad.players
      ? this.props.squad.players
          .map((player) => player.playerId)
          .filter(
            (playerId) => !this.props.gameState.bowlerCanBowlThisOver(playerId)
          )
      : [];
  }

  private getSubstitutes(): UUID[] {
    return this.props.gameState.squads[
      this.props.gameState.getBowlingTeam(this.props.matchFormat) - 1
    ]
      .filter(
        (teamPlayer) =>
          teamPlayer.matchRole === MatchRole.SUBSTITUTE ||
          teamPlayer.matchRole === MatchRole.REPLACED
      )
      .map((teamPlayer) => teamPlayer.playerId);
  }

  private isRetired(player: Player) {
    const percentageRetired = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BOWLER_RETIRED,
      player.playerId,
      null
    );

    return !(percentageRetired === null || Number.isNaN(percentageRetired));
  }

  private getRetiredBowlers(): UUID[] {
    return this.props.squad.players
      .filter((player) => this.isRetired(player))
      .map((player) => player.playerId);
  }

  private onRetire(percentToReturn: number, overToReturn: number) {
    services.currentGameService.retireBowler(
      this.props.selectedBowler,
      percentToReturn,
      overToReturn
    );
    this.setState({ retireModalOpen: false });
    this.props.onModalClose();
  }

  public render() {
    if (!this.props.gameState || !this.props.squad) return <div></div>;

    const conceded = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BOWLER_RUNS_CONCEDED,
      this.props.gameState.bowler
    );
    const balls = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BOWLER_BALLS_BOWLED,
      this.props.gameState.bowler
    );
    const fours = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BOWLER_FOURS_CONCEDED,
      this.props.gameState.bowler
    );
    const sixes = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BOWLER_SIXES_CONCEDED,
      this.props.gameState.bowler
    );
    const dots = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BOWLER_DOTS,
      this.props.gameState.bowler
    );
    const wickets = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BOWLER_WICKETS_TAKEN,
      this.props.gameState.bowler
    );
    const economy: string =
      balls === 0
        ? null
        : ((conceded / balls) * 6).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });

    const player: Player =
      this.props.selectedBowler &&
      this.props.squad.players.find(
        (player) => player.playerId.value === this.props.selectedBowler.value
      );
    const name = player && player.longName;
    const tableId: string = "bowler-selector-table";
    const primaryColour: string = this.props.primaryColour || "#888888";
    const secondaryColour: string = this.props.secondaryColour || "#777777";
    const textColour: string = getTitleColour(primaryColour);
    const textColourHeader: string = getTitleColour(secondaryColour);

    return (
      <div>
        {this.props.selectedBowler === null && (
          <TeamPlayerSelector
            selectedPlayer={this.props.selectedBowler}
            squad={this.props.squad}
            notAvailable={[
              ...this.getUnavailableBowlers(),
              ...this.getSubstitutes(),
            ]}
            retired={this.getRetiredBowlers()}
            label={"Bowler"}
            onSelect={(player: Player) => this.selectBowler(player)}
            disabled={
              this.props.gameState && this.props.gameState.bowler !== null
            }
            focussed={this.props.focussed}
          />
        )}
        {this.props.selectedBowler !== null && (
          <div className="bowler-image-and-scores">
            <PlayerView
              imgClasses={"scorecard-image"}
              player={
                this.props.squad &&
                this.props.squad.players &&
                this.props.squad.players.find(
                  (player) =>
                    player.playerId.value === this.props.selectedBowler.value
                )
              }
              canEdit={true}
              removeText={"Retire Bowler"}
              onRemove={() => {
                this.setState({ retireModalOpen: true });
                this.props.onModalOpen();
              }}
              showName={false}
              imgHeight={125}
              imgWidth={100}
              width={"100px"}
            />
            <div className="table-container">
              <table id={tableId} style={{ backgroundColor: primaryColour }}>
                <thead id={tableId}>
                  <tr
                    className="table-header"
                    id={tableId}
                    style={{
                      backgroundColor: secondaryColour,
                      color: textColourHeader,
                    }}
                  >
                    <td
                      className="table-header"
                      id={tableId}
                      align="right"
                    ></td>
                    <td className="table-header" id={tableId} align="right">
                      Runs
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Overs
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Wickets
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Fours
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Sixes
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Dots
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Economy
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr id={tableId} style={{ color: textColour }}>
                    <td id={tableId} className={"bowl-table-cell"}>
                      {name}
                    </td>
                    <td
                      id={tableId}
                      className={"bowl-table-cell"}
                      align="right"
                    >
                      {conceded}
                    </td>
                    <td
                      id={tableId}
                      className={"bowl-table-cell"}
                      align="right"
                    >
                      {this.props.gameState.getOverTextForBalls(balls)}
                    </td>
                    <td
                      id={tableId}
                      className={"bowl-table-cell"}
                      align="right"
                    >
                      {wickets}
                    </td>
                    <td
                      id={tableId}
                      className={"bowl-table-cell"}
                      align="right"
                    >
                      {fours}
                    </td>
                    <td
                      id={tableId}
                      className={"bowl-table-cell"}
                      align="right"
                    >
                      {sixes}
                    </td>
                    <td
                      id={tableId}
                      className={"bowl-table-cell"}
                      align="right"
                    >
                      {dots}
                    </td>
                    <td
                      id={tableId}
                      className={"bowl-table-cell"}
                      align="right"
                    >
                      {economy}
                    </td>
                  </tr>
                  <PlayerSelectorGlobalBiasesComponent
                    player={player}
                    onSpinnerEdit={(editing) =>
                      this.props.onSpinnerEdit(editing)
                    }
                    textColour={textColour}
                    primaryColour={primaryColour}
                    secondaryColour={secondaryColour}
                    tableId={tableId}
                    playerStrikeRateBiasProperty="bowlingGlobalStrikeRateBias"
                    playerWicketBiasProperty="bowlingGlobalWicketBias"
                    matchStrikeRateBiasProperty="bowlerMatchStrikeRateAdjustments"
                    matchWicketBiasProperty="bowlerMatchWicketPercentAdjustments"
                    matchStats={this.props.matchStats}
                    playerStats={this.props.playerStats}
                    squad={this.props.squad}
                    isBowler={true}
                  />
                </tbody>
              </table>
            </div>
          </div>
        )}
        {this.state.retireModalOpen && (
          <RetireModal
            open={this.state.retireModalOpen}
            gameState={this.props.gameState}
            matchFormat={this.props.matchFormat}
            onCancel={() => {
              this.setState({ retireModalOpen: false });
              this.props.onModalClose();
            }}
            onProceed={(percentReturn: number, overToReturn: number) =>
              this.onRetire(percentReturn, overToReturn)
            }
            player={
              this.props.selectedBowler &&
              this.props.squad.players.find(
                (player) =>
                  player.playerId.value === this.props.selectedBowler.value
              )
            }
          />
        )}
      </div>
    );
  }
}
