import { Chip, Icon, TextField } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";
import TimeAgo from "timeago-react";
import { MatchFormat } from "../../types/entities/match-format";
import { Series } from "../../types/entities/series";
import { Team } from "../../types/entities/team";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import {
  AdminPreferencesField,
  MatchSpecificSettings,
  PowerplayAdjustmentField,
} from "../../types/preferences/admin-preferences";
import { services } from "../../types/services";
import { UUID } from "../../types/uuid";
import { observableFrom } from "../component-utils";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import {
  PercentDistributionValue,
  PercentsDistributionBiasAdjuster,
} from "../stats-editing-components/percent-distribution-bias-adjuster";
import { bowlingTypeByPhaseMultipliersConfig } from "./admin-settings-page";
import BowlingTypeByPhaseMultipliersComponent from "./bowling-type-by-phase-multipliers-component";
import { ConfidenceSettingTable } from "./confidence-setting-table";

interface Props {
  initialSettings: MatchSpecificSettings;
  onUpdate: (matchSpecificSettings: MatchSpecificSettings) => void;
  onDelete?: () => void;
  onExpand?: () => void;
  expanded?: boolean;
  isDefault: boolean;
}

interface State {
  name: string;
  allTeams: Team[];
  allSeries: Series[];
  allFormats: MatchFormat[];
  renaming: boolean;
  powerplayPercentsExpanded: boolean;
}

export const powerplayPercentsExpansionButtonStyle = {
  border: "none",
  padding: "2px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

export type MatchSpecificSettingsTopLevelField =
  | "name"
  | "twmAtLastBallInnings1"
  | "twmAdditionInnings1"
  | "twmAtLastBallInnings2"
  | "twmAdditionInnings2"
  | "secondInningsPushAdjustment"
  | "secondInningsPushDilution"
  | "surgeStrikeRateBiasInnings1"
  | "surgeWicketBiasInnings1"
  | "surgeStrikeRateBiasInnings2"
  | "surgeWicketBiasInnings2"
  | "spinMultipliersByPhase"
  | "paceMultipliersByPhase"
  | "playerTwmBallsUntilSet"
  | "playerTwmFirstBallMultiplier"
  | "playerTwmOtherBallMultiplier"
  | "playerTwmDivisor";

export class MatchSpecificSettingsComponent extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      name: null,
      allTeams: [],
      allSeries: [],
      allFormats: [],
      renaming: false,
      powerplayPercentsExpanded: false,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.teamService
        .getAll()
        .subscribe((teams: Team[]) => this.setState({ allTeams: teams }))
    );
    this.subscriptions.push(
      services.seriesService
        .getAll()
        .subscribe((series: Series[]) => this.setState({ allSeries: series }))
    );
    this.subscriptions.push(
      services.matchFormatService
        .getAll()
        .subscribe((formats: MatchFormat[]) =>
          this.setState({ allFormats: formats })
        )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private update(settings: MatchSpecificSettings) {
    if (this.valid()) {
      this.props.onUpdate(settings);
    }
  }

  private updateConfidences(type: string, field: string, value: number) {
    this.update({
      ...this.props.initialSettings,
      adminConfidences: {
        ...this.props.initialSettings.adminConfidences,
        [type]: {
          ...this.props.initialSettings.adminConfidences[type],
          [field]: value,
        },
      },
    });
  }

  private updateField(
    field: MatchSpecificSettingsTopLevelField,
    value: AdminPreferencesField
  ) {
    this.update({
      ...this.props.initialSettings,
      [field]: value,
    });
  }

  private updatePowerplayAdjustment(
    field: PowerplayAdjustmentField,
    value: AdminPreferencesField
  ) {
    this.update({
      ...this.props.initialSettings,
      powerplayAdjustments: {
        ...this.props.initialSettings.powerplayAdjustments,
        [field]: value,
      },
    });
  }

  private updateBias(property: PercentDistributionValue, value: number) {
    this.update({
      ...this.props.initialSettings,
      powerplayAdjustments: {
        ...this.props.initialSettings.powerplayAdjustments,
        powerplayPercentBiases: {
          ...this.props.initialSettings.powerplayAdjustments
            .powerplayPercentBiases,
          [property]: value,
        },
      },
    });
  }

  private delete() {
    this.props.onDelete();
  }

  private valid(): boolean {
    return true;
  }

  private getTeamName(team: UUID): string {
    const foundTeam = this.state.allTeams.find(
      (t) => t.teamId.value === team.value
    );
    return (!!foundTeam && foundTeam.toString()) || "Unknown";
  }

  private getFormatName(format: UUID): string {
    const foundFormat = this.state.allFormats.find(
      (t) => t.matchFormatId.value === format.value
    );
    return (!!foundFormat && foundFormat.toString()) || "Unknown";
  }

  private getSeriesName(series: UUID): string {
    const foundSeries = this.state.allSeries.find(
      (t) => t.seriesId.value === series.value
    );
    return (!!foundSeries && foundSeries.toString()) || "Unknown";
  }

  private addMatchType(matchType: MatchType) {
    const settings = this.props.initialSettings;
    settings.matchTypes.push(matchType);
    this.update(settings);
  }

  private removeMatchType(index: number) {
    const settings = this.props.initialSettings;
    settings.matchTypes.splice(index, 1);
    this.update(settings);
  }

  private addTeam(team: UUID) {
    const settings = this.props.initialSettings;
    settings.teams.push(team);
    this.update(settings);
  }

  private removeTeam(index: number) {
    const settings = this.props.initialSettings;
    settings.teams.splice(index, 1);
    this.update(settings);
  }

  private addFormat(format: UUID) {
    const settings = this.props.initialSettings;
    settings.matchFormats.push(format);
    this.update(settings);
  }

  private removeFormat(index: number) {
    const settings = this.props.initialSettings;
    settings.matchFormats.splice(index, 1);
    this.update(settings);
  }

  private addSeries(series: UUID) {
    const settings = this.props.initialSettings;
    settings.series.push(series);
    this.update(settings);
  }

  private removeSeries(index: number) {
    const settings = this.props.initialSettings;
    settings.series.splice(index, 1);
    this.update(settings);
  }

  public render() {
    return (
      <div
        className={
          this.props.isDefault
            ? "admin-settings-default-content"
            : "match-specific-content-component"
        }
      >
        {!this.props.isDefault && (
          <div
            className="match-specific-settings-summary"
            onClick={() => this.props.onExpand()}
          >
            <div className="admin-settings-match-specific-buttons">
              <TooltipIconButton
                title={"Rename"}
                onClick={() => this.setState({ renaming: true })}
                icon={"edit"}
                disabled={this.state.renaming}
              />
              {!this.state.renaming && this.props.initialSettings.name}
              {this.state.renaming && (
                <TextField
                  value={
                    this.props.initialSettings === null
                      ? ""
                      : this.props.initialSettings.name
                  }
                  onChange={(event) =>
                    this.setState({
                      name: event.target.value,
                    })
                  }
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      this.updateField("name", this.state.name);
                      this.setState({ renaming: false, name: null });
                    }
                  }}
                  onBlur={(event) => {
                    this.updateField("name", this.state.name);
                    this.setState({ renaming: false, name: null });
                  }}
                  variant="outlined"
                  disabled={false}
                />
              )}
            </div>

            <div className="admin-settings-match-specific-buttons">
              <TimeAgo
                datetime={new Date(this.props.initialSettings.createdAt)}
              />
              <TooltipIconButton
                title={"Delete"}
                onClick={() => this.delete()}
                icon={"delete"}
              />
            </div>
          </div>
        )}
        {(this.props.expanded || this.props.isDefault) && (
          <div
            className={
              this.props.isDefault ? "" : "match-specific-content-expanded"
            }
          >
            {!this.props.isDefault && (
              <div className="match-specific-parameters-content">
                <div className="match-specific-settings-section-header">
                  Applies To Matches
                </div>
                <hr />
                <div className="match-specific-settings-parameters">
                  <div className="match-specific-settings-parameter-selector">
                    Match Types
                    <EnumSelector
                      enumObject={MatchType}
                      value={null}
                      readableValues={matchTypeNames}
                      onSelect={(matchType) => this.addMatchType(matchType)}
                      disabled={false}
                    />
                    <div>
                      {this.props.initialSettings.matchTypes.map(
                        (matchType, index) => (
                          <Chip
                            label={matchTypeNames[matchType]}
                            key={`already-selected-matchType-${index}`}
                            variant="outlined"
                            onDelete={() => this.removeMatchType(index)}
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className="match-specific-settings-parameter-selector">
                    Formats
                    <EntityAutoSelector
                      value={null}
                      label=""
                      options={observableFrom(this.state.allFormats)}
                      optionService={services.matchFormatService}
                      onSelect={(entity: MatchFormat) =>
                        this.addFormat(entity.matchFormatId)
                      }
                      canAddNew={false}
                      maxDropdownSize={20}
                      canEdit={false}
                    />
                    <div>
                      {this.props.initialSettings.matchFormats.map(
                        (format, index) => (
                          <Chip
                            label={this.getFormatName(format)}
                            key={`already-selected-format-${index}`}
                            variant="outlined"
                            onDelete={() => this.removeFormat(index)}
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className="match-specific-settings-parameter-selector">
                    Series
                    <EntityAutoSelector
                      value={null}
                      label=""
                      options={observableFrom(this.state.allSeries)}
                      optionService={services.seriesService}
                      onSelect={(entity: Series) =>
                        this.addSeries(entity.seriesId)
                      }
                      canAddNew={false}
                      maxDropdownSize={20}
                      canEdit={false}
                    />
                    <div>
                      {this.props.initialSettings.series.map(
                        (series, index) => (
                          <Chip
                            label={this.getSeriesName(series)}
                            key={`already-selected-series-${index}`}
                            variant="outlined"
                            onDelete={() => this.removeSeries(index)}
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className="match-specific-settings-parameter-selector">
                    Teams
                    <EntityAutoSelector
                      value={null}
                      label=""
                      options={observableFrom(this.state.allTeams)}
                      optionService={services.teamService}
                      onSelect={(entity: Team) => this.addTeam(entity.teamId)}
                      canAddNew={false}
                      canEdit={false}
                    />
                    <div>
                      {this.props.initialSettings.teams.map((team, index) => (
                        <Chip
                          label={this.getTeamName(team)}
                          key={`already-selected-team-${index}`}
                          variant="outlined"
                          onDelete={() => this.removeTeam(index)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="match-specific-settings-content">
              <div className="match-specific-settings-section-header">
                Settings
              </div>
              <hr />
              <div className="admin-settings-default-content">
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Thought Wicket Multiplier
                  </div>
                  <div className="admin-settings-default-content-subsection-header">
                    Innings Settings
                  </div>
                  <div className="thought-wicket-multiplier-settings">
                    <div className="thought-wicket-multiplier-section">
                      <div>Batting First</div>
                      <NumberSelector
                        label={"Multiplier at last ball"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={2}
                        initial={
                          this.props.initialSettings.twmAtLastBallInnings1
                        }
                        onValid={(valid: number) =>
                          this.updateField("twmAtLastBallInnings1", valid)
                        }
                      />
                      <NumberSelector
                        label={"Addition per ball"}
                        min={0}
                        max={1}
                        step={0.0001}
                        decimalPlaces={4}
                        initial={this.props.initialSettings.twmAdditionInnings1}
                        onValid={(valid: number) =>
                          this.updateField("twmAdditionInnings1", valid)
                        }
                      />
                    </div>
                    <div className="thought-wicket-multiplier-section">
                      <div>Batting Second</div>
                      <NumberSelector
                        label={"Multiplier at last ball"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={2}
                        initial={
                          this.props.initialSettings.twmAtLastBallInnings2
                        }
                        onValid={(valid: number) =>
                          this.updateField("twmAtLastBallInnings2", valid)
                        }
                      />
                      <NumberSelector
                        label={"Addition per ball"}
                        min={0}
                        max={1}
                        step={0.0001}
                        decimalPlaces={4}
                        initial={this.props.initialSettings.twmAdditionInnings2}
                        onValid={(valid: number) =>
                          this.updateField("twmAdditionInnings2", valid)
                        }
                      />
                    </div>
                  </div>

                  <div className="admin-settings-default-content-subsection-header">
                    Player Settings
                  </div>
                  <div className="thought-wicket-multiplier-settings">
                    <NumberSelector
                      label={"Balls until set"}
                      min={0}
                      max={1000}
                      step={1}
                      decimalPlaces={0}
                      initial={
                        this.props.initialSettings.playerTwmBallsUntilSet
                      }
                      onValid={(valid: number) =>
                        this.updateField("playerTwmBallsUntilSet", valid)
                      }
                    />
                    <NumberSelector
                      label={"Multiplier at first ball"}
                      min={0}
                      max={100}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.initialSettings.playerTwmFirstBallMultiplier
                      }
                      onValid={(valid: number) =>
                        this.updateField("playerTwmFirstBallMultiplier", valid)
                      }
                    />
                    <NumberSelector
                      label={"Divisor"}
                      min={0}
                      max={1000}
                      step={1}
                      decimalPlaces={0}
                      initial={this.props.initialSettings.playerTwmDivisor}
                      onValid={(valid: number) =>
                        this.updateField("playerTwmDivisor", valid)
                      }
                    />
                    <NumberSelector
                      label={"Multiplier at other balls"}
                      min={0}
                      max={100}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.initialSettings.playerTwmOtherBallMultiplier
                      }
                      onValid={(valid: number) =>
                        this.updateField("playerTwmOtherBallMultiplier", valid)
                      }
                    />
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Bowling Type by Phase Multipliers
                  </div>
                  {bowlingTypeByPhaseMultipliersConfig.map((config) => (
                    <BowlingTypeByPhaseMultipliersComponent
                      value={this.props.initialSettings[config.property]}
                      title={config.title}
                      onChange={(updatedMultipliers: number[][]) =>
                        this.updateField(config.property, updatedMultipliers)
                      }
                      key={config.property}
                    />
                  ))}
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Second Innings Push Adjust
                  </div>
                  <div className="second-innings-push-adjust-settings">
                    <NumberSelector
                      label={"Adjustment"}
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.initialSettings.secondInningsPushAdjustment
                      }
                      onValid={(valid: number) =>
                        this.updateField("secondInningsPushAdjustment", valid)
                      }
                    />
                    <NumberSelector
                      label={"Dilution"}
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.initialSettings.secondInningsPushDilution
                      }
                      onValid={(valid: number) =>
                        this.updateField("secondInningsPushDilution", valid)
                      }
                    />
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Powerplay Settings
                  </div>
                  <div className="second-innings-push-adjust-settings">
                    <NumberSelector
                      label={"Powerplay Default Push Adjustment"}
                      min={-100}
                      max={100}
                      step={1}
                      decimalPlaces={0}
                      initial={
                        this.props.initialSettings.powerplayAdjustments
                          .basePushAdjustment
                      }
                      onValid={(valid: number) =>
                        this.updatePowerplayAdjustment(
                          "basePushAdjustment",
                          valid
                        )
                      }
                    />
                    <NumberSelector
                      label={"Powerplay Wicket Bias"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.initialSettings.powerplayAdjustments
                          .baseWicketBias
                      }
                      onValid={(valid: number) =>
                        this.updatePowerplayAdjustment("baseWicketBias", valid)
                      }
                    />

                    <button
                      style={powerplayPercentsExpansionButtonStyle}
                      onClick={() =>
                        this.setState({
                          powerplayPercentsExpanded:
                            !this.state.powerplayPercentsExpanded,
                        })
                      }
                    >
                      <div>Powerplay Percent Bias</div>
                      <Icon>
                        {this.state.powerplayPercentsExpanded
                          ? "expand_less"
                          : "expand_more"}
                      </Icon>
                    </button>
                    {this.state.powerplayPercentsExpanded && (
                      <PercentsDistributionBiasAdjuster
                        biases={
                          this.props.initialSettings.powerplayAdjustments
                            .powerplayPercentBiases
                        }
                        updateValue={(
                          property: PercentDistributionValue,
                          value: number
                        ) => this.updateBias(property, value)}
                      />
                    )}
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Surge Settings
                  </div>
                  <div className="thought-wicket-multiplier-settings">
                    <div className="thought-wicket-multiplier-section">
                      Innings 1
                      <NumberSelector
                        label={"Strike Rate Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.props.initialSettings.surgeStrikeRateBiasInnings1
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeStrikeRateBiasInnings1", valid)
                        }
                      />
                      <NumberSelector
                        label={"Wicket % Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.props.initialSettings.surgeWicketBiasInnings1
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeWicketBiasInnings1", valid)
                        }
                      />
                    </div>
                    <div className="thought-wicket-multiplier-section">
                      Innings 2
                      <NumberSelector
                        label={"Strike Rate Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.props.initialSettings.surgeStrikeRateBiasInnings2
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeStrikeRateBiasInnings2", valid)
                        }
                      />
                      <NumberSelector
                        label={"Wicket % Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.props.initialSettings.surgeWicketBiasInnings2
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeWicketBiasInnings2", valid)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.props.initialSettings.teams.length === 0 && (
                <div>
                  <hr />
                  <div className="admin-settings-default-content-section-header">
                    Confidence Limits
                  </div>
                  <ConfidenceSettingTable
                    adminConfidences={
                      this.props.initialSettings.adminConfidences
                    }
                    onUpdate={(type, field, value) =>
                      this.updateConfidences(type, field, value)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
