import { Box, Button, Select } from "@mui/material";
import { Component, ReactNode } from "react";
import { Observable, Subscription } from "rxjs";

import { HistoricPlayerFormUpdateMessage } from "../../services/player-stats-service";
import { Player } from "../../types/entities/player";
import { MatchType } from "../../types/enums/match-type";
import {
  PlayerFormChartType,
  playerFormChartTypeMenuItems,
  playerFormChartTypeNames,
} from "../../types/enums/player-form-chart-type";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import { services, showConfirmationDialog } from "../../types/services";
import { HistoricPlayerStatsWrapper } from "../../types/stats/historic-form";
import { dateToAge } from "../../types/util-functions";
import { calculateProgress, observableFrom } from "../component-utils";
import { Option } from "../entity-management/entity-selectors/entity-auto-selector";
import { PlayerSelector } from "../entity-management/entity-selectors/player-selector";

import { LinearProgressWithLabel } from "./all-player-stats-component";
import { PlayerFormChart } from "./player-form-chart";
import { PlayerFormPreferencesComponent } from "./player-form-preferences-component";

interface State {
  selectedPlayers: Player[];
  allPlayers: Player[];
  generatingHistoricForm: boolean;
  historicPlayerForm: Map<string, HistoricPlayerStatsWrapper>;
  latestUpdate: HistoricPlayerFormUpdateMessage;
  selectedChartType: PlayerFormChartType;
  adminPreferences: AdminPreferences;
}

interface Props {
  matchType: MatchType;
}

export class AllPlayerFormComponent extends Component<Props, State> {
  public subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      selectedPlayers: [],
      allPlayers: [],
      generatingHistoricForm: false,
      historicPlayerForm: null,
      latestUpdate: null,
      selectedChartType:
        PlayerFormChartType.EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE,
      adminPreferences: null,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.playerService
        .getAll()
        .subscribe((players: Player[]) =>
          this.setState({ allPlayers: players })
        )
    );

    this.subscriptions.push(
      services.playerStatsService.historicFormSubject.subscribe(
        (update: Map<string, HistoricPlayerStatsWrapper>) =>
          this.setState({ historicPlayerForm: update })
      )
    );

    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (update: AdminPreferences) =>
          this.setState({ adminPreferences: update })
      )
    );

    this.subscriptions.push(
      services.playerStatsService.historicFormRequestSubject.subscribe(
        (update: HistoricPlayerFormUpdateMessage) => {
          if (update.lastPlayer === "Player Form Calculation Complete") {
            this.state.selectedPlayers.forEach((p) =>
              services.playerStatsService.getHistoricPlayerForm(
                p.playerId,
                this.props.matchType
              )
            );
            this.setState({
              latestUpdate: update,
              generatingHistoricForm: false,
            });
          } else {
            this.setState({ latestUpdate: update });
          }
        }
      )
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.matchType !== prevProps.matchType) {
      this.state.selectedPlayers.forEach((p) =>
        services.playerStatsService.getHistoricPlayerForm(
          p.playerId,
          this.props.matchType
        )
      );
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private addPlayer(player: Player) {
    services.playerStatsService.getHistoricPlayerForm(
      player.playerId,
      this.props.matchType
    );
    this.setState({
      selectedPlayers: [...this.state.selectedPlayers, player],
    });
  }

  private updatePreferences(field: string, value: number) {
    const toUpdate = {
      ...this.state.adminPreferences,
      [field]: value,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private getEligiblePlayers(): Observable<Player[]> {
    return observableFrom(
      this.state.allPlayers.filter(
        (player) =>
          this.state.selectedPlayers.find(
            (p) => p.playerId.value === player.playerId.value
          ) === undefined
      )
    );
  }

  private playerRenderer(props, option: Option<Player>): ReactNode {
    const player = option.object as Player;
    let im: ReactNode = services.teamService.findFlagForNationalTeam(
      player.nationalTeam
    );
    if (!option.new) {
      return (
        <div {...props} className="player-select-render">
          <div>{player.toString()}</div>
          <div className="player-select-side-text italic">
            {dateToAge(player.dob)}y<div className="small-image">{im}</div>
          </div>
        </div>
      );
    } else {
      return <div {...props}>Add player: {player.toString()}</div>;
    }
  }

  private handleChartTypeSelection(newValue) {
    const value: PlayerFormChartType =
      PlayerFormChartType[
        Object.keys(PlayerFormChartType).find(
          (key) => key.toString() === newValue.props.value
        )
      ];
    this.setState({ selectedChartType: value });
  }

  public render() {
    const results: ReactNode[] = [];
    !!this.state.historicPlayerForm &&
      this.state.selectedPlayers.forEach((player) =>
        results.push(<div>{player.playerId.value}</div>)
      );

    return (
      <div className="historic-player-stats-body">
        {!this.state.generatingHistoricForm && (
          <div className="historic-player-form-component">
            <div className="historic-player-form-header">
              <PlayerSelector
                value={null}
                onSelect={(player: Player) => this.addPlayer(player)}
                options={this.getEligiblePlayers()}
                renderer={(props, option) => this.playerRenderer(props, option)}
                classes="historic-form-squad-player-selector"
              />
              <PlayerFormPreferencesComponent
                preferences={this.state.adminPreferences}
                onUpdate={(field, value) =>
                  this.updatePreferences(field, value)
                }
              />
              <div className="historic-player-form-buttons">
                <Button
                  onClick={() =>
                    showConfirmationDialog(
                      () => {
                        this.setState({ generatingHistoricForm: true }, () =>
                          services.playerStatsService.recalculateHistoricPlayerFormForIds(
                            this.state.selectedPlayers
                          )
                        );
                      },
                      "Regenerate Historic Player Form",
                      "Are you sure?"
                    )
                  }
                  variant={"contained"}
                  color="primary"
                  disabled={this.state.generatingHistoricForm}
                >
                  RECALCULATE SELECTED PLAYER FORM
                </Button>
                <Button
                  onClick={() =>
                    showConfirmationDialog(
                      () => {
                        this.setState(
                          {
                            generatingHistoricForm: true,
                            historicPlayerForm: null,
                            selectedPlayers: [],
                          },
                          () =>
                            services.playerStatsService.recalculateHistoricPlayerForm(
                              this.props.matchType
                            )
                        );
                      },
                      "Regenerate Historic Player Form",
                      "Are you sure?"
                    )
                  }
                  variant={"contained"}
                  color="secondary"
                  disabled={this.state.generatingHistoricForm}
                >
                  RECALCULATE ALL PLAYER FORM
                </Button>
              </div>
            </div>
            <hr />
            <div className="player-form-chart-and-settings">
              <div className="player-form-container">
                <Select
                  id="enum-select"
                  className="select"
                  value={this.state.selectedChartType}
                  onChange={(event, newValue) =>
                    this.handleChartTypeSelection(newValue)
                  }
                  disabled={false}
                  renderValue={(option) => playerFormChartTypeNames[option]}
                  variant="standard"
                >
                  {playerFormChartTypeMenuItems}
                </Select>
                <PlayerFormChart
                  selectedPlayers={this.state.selectedPlayers}
                  playerStats={this.state.historicPlayerForm}
                  selectedChartType={this.state.selectedChartType}
                  adminPreferences={this.state.adminPreferences}
                />
              </div>
            </div>
            <hr />
            <span className="italic">
              The chart above shows historic player form in three categories:
              <ul>
                <li>
                  All Time: This is the average of all balls faced/bowled over
                  the player's career up to the date displayed.
                </li>
                <li>
                  Rolling: This is the average over the previous X games up to
                  the date displayed (X settable with rolling confidence size).
                </li>
                <li>
                  Effective: This is a blend of All Time/Rolling stats (%
                  settable above), multiplied by the effective confidence in the
                  case of SR and Wicket Biases.
                </li>
              </ul>
              When we calculate a player's stats for a match, we can use the
              effective historic player form (See form settings) to determine
              the opponent's skill for each ball faced/bowled.
            </span>
          </div>
        )}
        {this.state.generatingHistoricForm && (
          <div className="loading-bar">
            Loading...
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel
                value={calculateProgress(this.state.latestUpdate)}
              />
            </Box>
            {!!this.state.latestUpdate && (
              <div>
                {this.state.latestUpdate.done}/{this.state.latestUpdate.size}{" "}
                {this.state.latestUpdate.lastPlayer}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
