import { ReactNode } from "react";

export enum PercentAdjustModuleType {
  PERCENT_BIAS = "PERCENT_BIAS",
  BATSMAN_PERCENT_BIAS = "BATSMAN_PERCENT_BIAS",
  BOWLER_PERCENT_BIAS = "BOWLER_PERCENT_BIAS",
  POWERPLAY_PERCENT_BIAS = "POWERPLAY_PERCENT_BIAS",
  FREE_HIT = "FREE_HIT",
}

export class PercentAdjustData {
  subType: string;
  enabled: boolean;
  constructor(enabled: boolean, subType: string) {
    this.enabled = enabled;
    this.subType = subType;
  }
}

export class PercentBiasAdjustData extends PercentAdjustData {
  confidenceLimit: number;
  constructor(enabled: boolean, subType: string, confidenceLimit: number) {
    super(enabled, subType);
    this.confidenceLimit = confidenceLimit;
  }
}

export class FreeHitPercentAdjustData extends PercentAdjustData {
  freeHitWicketPercent: number;

  constructor(freeHitWicketPercent: number, enabled: boolean, subType: string) {
    super(enabled, subType);
    this.freeHitWicketPercent = freeHitWicketPercent;
  }
}

export const percentAdjustModuleCanBeDisabled: Record<
  PercentAdjustModuleType,
  boolean
> = {
  PERCENT_BIAS: false,
  BATSMAN_PERCENT_BIAS: true,
  BOWLER_PERCENT_BIAS: true,
  POWERPLAY_PERCENT_BIAS: true,
  FREE_HIT: true,
};

export const percentAdjustModuleToDataTypes: Record<
  PercentAdjustModuleType,
  (any) => PercentAdjustData
> = {
  PERCENT_BIAS: (json: any) =>
    new PercentBiasAdjustData(json.enabled, json.subType, json.confidenceLimit),
  BATSMAN_PERCENT_BIAS: (json: any) =>
    new PercentBiasAdjustData(json.enabled, json.subType, json.confidenceLimit),
  BOWLER_PERCENT_BIAS: (json: any) =>
    new PercentBiasAdjustData(json.enabled, json.subType, json.confidenceLimit),
  POWERPLAY_PERCENT_BIAS: (json: any) =>
    new PercentAdjustData(json.enabled, json.subType),
  FREE_HIT: (json: any) =>
    new FreeHitPercentAdjustData(
      json.freeHitWicketPercent,
      json.enabled,
      json.subType
    ),
};

export const percentAdjustModuleNames: Record<PercentAdjustModuleType, string> =
  {
    PERCENT_BIAS: "Ground strike rate distribution biases",
    BATSMAN_PERCENT_BIAS: "Batsman strike rate distribution biases",
    BOWLER_PERCENT_BIAS: "Bowler strike rate distribution biases",
    POWERPLAY_PERCENT_BIAS: "Powerplay percent adjustment",
    FREE_HIT: "Free hit",
  };

export const percentAdjustModuleTooltips: Record<
  PercentAdjustModuleType,
  string
> = {
  PERCENT_BIAS: "See ground strike rate settings",
  BATSMAN_PERCENT_BIAS: "See batsman strike rate settings",
  BOWLER_PERCENT_BIAS: "See bowler strike rate settings",
  POWERPLAY_PERCENT_BIAS: "See match stats powerplay settings",
  FREE_HIT: "Free hit settings",
};

export interface PercentAdjustModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number";
  constraints: any;
}

export const percentAdjustDataProperties: Record<
  PercentAdjustModuleType,
  PercentAdjustModuleDataProperty[]
> = {
  PERCENT_BIAS: [
    {
      humanReadableName: "Confidence Limit",
      propertyName: "confidenceLimit",
      type: "number",
      constraints: { min: 0, max: 100000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            How many balls should have been bowled before we count ground
            percent distribution biases fully?
          </p>
        </div>
      ),
    },
  ],
  BATSMAN_PERCENT_BIAS: [
    {
      humanReadableName: "Confidence Limit",
      propertyName: "confidenceLimit",
      type: "number",
      constraints: { min: 0, max: 100000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            How many balls should have been bowled before we count batsman
            percent distribution biases fully?
          </p>
        </div>
      ),
    },
  ],
  BOWLER_PERCENT_BIAS: [
    {
      humanReadableName: "Confidence Limit",
      propertyName: "confidenceLimit",
      type: "number",
      constraints: { min: 0, max: 100000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            How many balls should have been bowled before we count bowler
            percent distribution biases fully?
          </p>
        </div>
      ),
    },
  ],
  POWERPLAY_PERCENT_BIAS: [],
  FREE_HIT: [
    {
      humanReadableName: "Wicket Percent",
      description: "Tiny wicket percent to account for run outs on free hit",
      propertyName: "freeHitWicketPercent",
      type: "number",
      constraints: { min: 0, max: 1, step: 0.0001, decimalPlaces: 4 },
    },
  ],
};

export function deserializePercentAdjustModules(
  json: any
): Map<PercentAdjustModuleType, PercentAdjustData> {
  const percentAdjustModules: Map<PercentAdjustModuleType, PercentAdjustData> =
    new Map();
  Object.keys(PercentAdjustModuleType).forEach((moduleType) => {
    percentAdjustModules.set(
      PercentAdjustModuleType[moduleType],
      percentAdjustModuleToDataTypes[PercentAdjustModuleType[moduleType]](
        json[moduleType]
      )
    );
  });
  return percentAdjustModules;
}

export function serializePercentAdjustModules(
  percentAdjustModules: Map<PercentAdjustModuleType, PercentAdjustData>
): any {
  const result: any = {};
  percentAdjustModules.forEach((data, moduleType) => {
    result[moduleType] = data;
  });
  return result;
}
