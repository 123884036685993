import { Switch, Tooltip } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import { KeycloakUser } from "../../services/keycloak-service";
import { GameState } from "../../types/entities/game-state";
import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import {
  getTruePriceResults,
  SimulatorScenario,
  UserPreferences,
} from "../../types/preferences/preferences";
import { SimulatorRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { NodeHealth } from "../../types/simulator/node-health";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import AppAlert from "../common-components/app-alert";
import RunSimulatorButton from "../common-components/run-simulator-button";
import { noHealthyNodes, noSimulationsAllowed } from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { ComparedUserSelector } from "../stats-editing-components/compared-user-selector";
import { StatsLoadingPage } from "../stats-loading-page/stats-loading-page";

import { TrafficLight } from "../../types/enums/traffic-light";
import TrafficLightDisplay from "../stats-editing-components/traffic-light-display";
import BallsInEachBracket from "./balls-in-each-bracket/balls-in-each-bracket";
import { SimulatorPlayerMedianQuestionDisplay } from "./player-medians-and-odds/simulator-player-median-question-display";
import { RecordedSimulationsDisplay } from "./recorded-simulations-display";
import { SimulatorMatchOddsDisplay } from "./simulator-match-odds-display";
import { SimulatorMedianQuestionDisplay } from "./simulator-median-question-display";
import { SimulatorMedianScoreAtOverDisplay } from "./simulator-median-score-at-over-display";
import { SimulatorMiscStatsDisplay } from "./simulator-misc-stats-display";
import { SimulatorNextManOutDisplay } from "./simulator-next-man-out-display";
import { SimulatorOversDistributionDisplay } from "./simulator-overs-distribution-display";
import { SimulatorRunsLineDisplay } from "./simulator-runs-line-display";
import { SimulatorSettingsModal } from "./simulator-settings-modal";
import { SimulatorSurgeStatsDisplay } from "./simulator-surge-stats-display";
import { SimulatorTopBatDisplay } from "./simulator-top-bat-display";

interface State {
  gameState: GameState;
  matchFormat: MatchFormat;
  latestResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedUserName: string;
  comparedMatchStats: MatchStatsWrapper;
  team1: Team;
  team2: Team;
  squad1: Squad;
  squad2: Squad;
  match: Match;
  ground: Ground;
  settingsModalOpen: boolean;
  matchOddsCollapsed: boolean;
  runsLineCollapsed: boolean;
  inningsStatsCollapsed: boolean;
  surgeStatsCollapsed: boolean;
  medianQuestionDisplayCollapsed: boolean;
  playerMedianQuestionDisplayCollapsed: boolean;
  nextManOutCollapsed: boolean;
  topBatCollapsed: boolean;
  recordedSimsCollapsed: boolean;
  overDistributionCollapsed: boolean;
  ballsInEachBracketCollapsed: boolean;
  userPreferences: UserPreferences;
  loading: boolean;
  overDistributionInnings: number;
  nodeHealth: NodeHealth[];
  latestVersion: string;
  matchStats: MatchStatsWrapper;
  playerStats: Map<string, PlayerStatsWrapper>;
}

export class SimulatorPage extends Component<{}, State> {
  private subscriptions: Subscription[];
  private static readonly DEFAULT_STATE = {
    gameState: null,
    matchFormat: null,
    latestResults: new Map(),
    comparedResults: new Map(),
    comparedUserName: null,
    comparedMatchStats: null,
    team1: null,
    team2: null,
    squad1: null,
    squad2: null,
    match: null,
    ground: null,
    settingsModalOpen: false,
    matchOddsCollapsed: false,
    runsLineCollapsed: false,
    inningsStatsCollapsed: false,
    surgeStatsCollapsed: false,
    nextManOutCollapsed: false,
    topBatCollapsed: false,
    recordedSimsCollapsed: false,
    overDistributionCollapsed: false,
    medianQuestionDisplayCollapsed: false,
    playerMedianQuestionDisplayCollapsed: false,
    ballsInEachBracketCollapsed: false,
    userPreferences: null,
    loading: false,
    overDistributionInnings: 1,
    nodeHealth: null,
    latestVersion: null,
    matchStats: null,
    playerStats: null,
  };

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      ...SimulatorPage.DEFAULT_STATE,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.currentGameService.currentMatchSubject.subscribe(
        (currentMatch: Match) => this.setState({ match: currentMatch })
      )
    );

    this.subscriptions.push(
      services.currentGameService.groundSubject.subscribe((ground: Ground) =>
        this.setState({ ground })
      )
    );

    this.subscriptions.push(
      services.currentGameService.squadsSubject.subscribe(
        ([squad1, squad2]: Squad[]) => this.setState({ squad1, squad2 })
      )
    );

    this.subscriptions.push(
      services.currentGameService.teamsSubject.subscribe(
        ([team1, team2]: Team[]) => this.setState({ team1, team2 })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentStateSubject.subscribe(
        (gameState: GameState) => this.setState({ gameState })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentMatchFormatSubject.subscribe(
        (matchFormat: MatchFormat) => this.setState({ matchFormat })
      )
    );

    this.subscriptions.push(
      services.simulationService.latestSimulationResultSubject.subscribe(
        (latestResults) => this.setState({ latestResults })
      )
    );

    this.subscriptions.push(
      services.simulationService.loadingSubject.subscribe((loading) =>
        this.setState({ loading })
      )
    );

    this.subscriptions.push(
      services.userService.userPreferencesSubject.subscribe((userPreferences) =>
        this.setState({ userPreferences })
      )
    );

    this.subscriptions.push(
      services.keycloakService.comparedUserSubject.subscribe(
        (comparedUser: KeycloakUser) =>
          this.setState({
            comparedUserName: !!comparedUser ? comparedUser.name : null,
          })
      )
    );

    this.subscriptions.push(
      services.simulationService.comparedUserResultsSubject.subscribe(
        (comparedResults: Map<string, [SimulatorScenario, SimulationResult]>) =>
          this.setState({ comparedResults })
      )
    );

    this.subscriptions.push(
      services.matchStatsService.comparedMatchStatsSubject.subscribe(
        (comparedMatchStats: MatchStatsWrapper) =>
          this.setState({ comparedMatchStats })
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeHealthSubject.subscribe(
        (nodes: NodeHealth[]) => {
          if (nodes) {
            this.setState({
              nodeHealth: nodes,
            });
          }
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeVersionSubject.subscribe(
        (latestVersion: string) => {
          this.setState({ latestVersion: latestVersion });
        }
      )
    );

    this.subscriptions.push(
      services.matchStatsService.matchStatsSubject.subscribe(
        (matchStats: MatchStatsWrapper) => this.setState({ matchStats })
      )
    );

    this.subscriptions.push(
      services.playerStatsService.playerStatsSubject.subscribe(
        (playerStats: Map<string, PlayerStatsWrapper>) =>
          this.setState({ playerStats })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private toggleCollapse(type: string) {
    this.setState({
      ...this.state,
      [type]: !this.state[type],
    });
  }

  private refreshSim() {
    services.simulationService.removeSimulationResults();
    services.simulationService.simulate();
  }

  private updatePreferences(settings: UserPreferences) {
    services.userService
      .updateUserPreferences(settings)
      .then(() => this.setState({ settingsModalOpen: false }));
  }

  private updateScenario(scenario: SimulatorScenario, enabled: boolean) {
    this.state.userPreferences.simulatorScenarios.forEach((userScenario) => {
      if (userScenario.scenarioId === scenario.scenarioId) {
        userScenario.enabled = enabled;
      }
    });

    this.updatePreferences(this.state.userPreferences);
  }

  private updateTraderConfidence(trafficLight: TrafficLight) {
    services.matchStatsService.updateMatchStats({
      ...this.state.matchStats,
      matchStats: {
        ...this.state.matchStats.matchStats,
        traderConfidence: trafficLight,
      },
    } as MatchStatsWrapper);
  }

  public render() {
    const truePriceResults = getTruePriceResults(this.state.latestResults);
    const comparedTruePriceResults = getTruePriceResults(
      this.state.comparedResults
    );

    return (
      <SimulatorRoute>
        <div className="full-push-background-light with-navbar simulator-page">
          {noSimulationsAllowed(
            this.state.match,
            this.state.userPreferences
          ) && (
            <AppAlert>
              Check if you have at least 1 simulation enabled in settings and
              that the simulator is enabled.
            </AppAlert>
          )}
          {noHealthyNodes(this.state.nodeHealth, this.state.latestVersion) && (
            <AppAlert severity="warning">
              You have no nodes, please set them up to run the simulator
            </AppAlert>
          )}
          {!this.state.gameState && (
            <div className="page-title">No match selected</div>
          )}
          {this.state.gameState &&
            this.state.matchFormat &&
            this.state.matchFormat.inningsConfiguration.length !== 2 && (
              <div className="page-title">
                Simulator not currently available for format:{" "}
                {this.state.matchFormat.name}
              </div>
            )}

          {this.state.gameState &&
            this.state.matchFormat &&
            this.state.match &&
            this.state.matchFormat.inningsConfiguration.length === 2 && (
              <div className="simulator-buttons-and-lights">
                {this.state.matchStats && (
                  <TrafficLightDisplay
                    value={this.state.matchStats.matchStats.traderConfidence}
                    lampRadiusPx={20}
                    paddingPx={8}
                    onChange={(trafficLight) =>
                      this.updateTraderConfidence(trafficLight)
                    }
                  />
                )}
                <div className="simulator-buttons">
                  {this.state.latestResults && !!truePriceResults && (
                    <div className="simulation-status italic">
                      Latest run: {truePriceResults[1].numberOfSimulations} sims
                      in {truePriceResults[1].timeTaken}ms
                    </div>
                  )}
                  <Tooltip title={"Simulator Enabled for Match"}>
                    <Switch
                      checked={this.state.match.simsEnabled}
                      onChange={() =>
                        services.matchService.toggleSims(
                          this.state.match.matchId
                        )
                      }
                    />
                  </Tooltip>
                  <ComparedUserSelector />
                  {this.state.gameState &&
                    this.state.gameState.initialStatsLoaded && (
                      <RunSimulatorButton
                        isDisabled={noSimulationsAllowed(
                          this.state.match,
                          this.state.userPreferences
                        )}
                        onClick={() => this.refreshSim()}
                      />
                    )}
                  <TooltipIconButton
                    title="Settings"
                    disabled={false}
                    onClick={() => {
                      this.setState({ settingsModalOpen: true });
                    }}
                    icon="settings"
                    colour="#f8f8ff"
                  />
                </div>
              </div>
            )}

          <div className="simulator-page-parts">
            <div className="match-odds-and-recorded-sims-container">
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded && (
                  <SimulatorMatchOddsDisplay
                    gameState={this.state.gameState}
                    matchFormat={this.state.matchFormat}
                    latestResults={this.state.latestResults}
                    comparedResults={this.state.comparedResults}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    match={this.state.match}
                    loading={this.state.loading}
                    collapsed={this.state.matchOddsCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("matchOddsCollapsed")
                    }
                    onScenarioUpdate={(scenario, enabled) =>
                      this.updateScenario(scenario, enabled)
                    }
                    userPreferences={this.state.userPreferences}
                    comparedMatchStats={this.state.comparedMatchStats}
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded &&
                truePriceResults && (
                  <SimulatorMiscStatsDisplay
                    gameState={this.state.gameState}
                    latestResult={truePriceResults[1]}
                    comparedResults={
                      !!comparedTruePriceResults && comparedTruePriceResults[1]
                    }
                    comparedUserName={this.state.comparedUserName}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    loading={this.state.loading}
                    collapsed={this.state.inningsStatsCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("inningsStatsCollapsed")
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                MatchFormat.hasSurge(this.state.matchFormat) &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded &&
                truePriceResults && (
                  <SimulatorSurgeStatsDisplay
                    gameState={this.state.gameState}
                    latestResult={truePriceResults[1]}
                    comparedResults={
                      !!comparedTruePriceResults && comparedTruePriceResults[1]
                    }
                    comparedUserName={this.state.comparedUserName}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    loading={this.state.loading}
                    collapsed={this.state.surgeStatsCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("surgeStatsCollapsed")
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded && (
                  <SimulatorMedianQuestionDisplay
                    gameState={this.state.gameState}
                    matchFormat={this.state.matchFormat}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    squad1={this.state.squad1}
                    squad2={this.state.squad2}
                    loading={this.state.loading}
                    collapsed={this.state.medianQuestionDisplayCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("medianQuestionDisplayCollapsed")
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded && (
                  <SimulatorPlayerMedianQuestionDisplay
                    gameState={this.state.gameState}
                    matchFormat={this.state.matchFormat}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    squad1={this.state.squad1}
                    squad2={this.state.squad2}
                    loading={this.state.loading}
                    collapsed={this.state.playerMedianQuestionDisplayCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse(
                        "playerMedianQuestionDisplayCollapsed"
                      )
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded && (
                  <SimulatorMedianScoreAtOverDisplay
                    gameState={this.state.gameState}
                    matchFormat={this.state.matchFormat}
                    loading={this.state.loading}
                    collapsed={this.state.medianQuestionDisplayCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("medianQuestionDisplayCollapsed")
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded &&
                truePriceResults && (
                  <SimulatorOversDistributionDisplay
                    showInningsToggle={true}
                    onInningsChange={(innings) =>
                      this.setState({ overDistributionInnings: innings })
                    }
                    gameState={this.state.gameState}
                    matchFormat={this.state.matchFormat}
                    latestResult={truePriceResults[1]}
                    comparedResults={
                      !!comparedTruePriceResults && comparedTruePriceResults[1]
                    }
                    comparedUserName={this.state.comparedUserName}
                    team={
                      this.state.overDistributionInnings === 1
                        ? this.state.team2
                        : this.state.team1
                    }
                    squad={
                      this.state.overDistributionInnings === 1
                        ? this.state.squad2
                        : this.state.squad1
                    }
                    loading={this.state.loading}
                    collapsed={this.state.overDistributionCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("overDistributionCollapsed")
                    }
                    innings={this.state.overDistributionInnings}
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded &&
                this.state.team1 &&
                this.state.team2 &&
                this.state.squad1 &&
                this.state.squad2 &&
                truePriceResults && (
                  <RecordedSimulationsDisplay
                    latestResult={truePriceResults[1]}
                    matchFormat={this.state.matchFormat}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    squad1={this.state.squad1}
                    squad2={this.state.squad2}
                    match={this.state.match}
                    ground={this.state.ground}
                    collapsed={this.state.recordedSimsCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("recordedSimsCollapsed")
                    }
                    matchStats={this.state.matchStats}
                    playerStats={this.state.playerStats}
                  />
                )}
            </div>
            <div className="match-odds-and-recorded-sims-container">
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded && (
                  <SimulatorRunsLineDisplay
                    gameState={this.state.gameState}
                    latestResults={this.state.latestResults}
                    comparedResults={this.state.comparedResults}
                    comparedUserName={this.state.comparedUserName}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    matchFormat={this.state.matchFormat}
                    loading={this.state.loading}
                    collapsed={this.state.runsLineCollapsed}
                    userPreferences={this.state.userPreferences}
                    toggleCollapse={() =>
                      this.toggleCollapse("runsLineCollapsed")
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded && (
                  <SimulatorNextManOutDisplay
                    gameState={this.state.gameState}
                    latestResults={this.state.latestResults}
                    comparedResults={this.state.comparedResults}
                    comparedUserName={this.state.comparedUserName}
                    squad1={this.state.squad1}
                    squad2={this.state.squad2}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    matchFormat={this.state.matchFormat}
                    loading={this.state.loading}
                    collapsed={this.state.nextManOutCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("nextManOutCollapsed")
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded &&
                this.state.gameState.innings === 1 && (
                  <SimulatorTopBatDisplay
                    gameState={this.state.gameState}
                    latestResults={this.state.latestResults}
                    comparedResults={this.state.comparedResults}
                    comparedUserName={this.state.comparedUserName}
                    squad1={this.state.squad1}
                    squad2={this.state.squad2}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    matchFormat={this.state.matchFormat}
                    loading={this.state.loading}
                    innings={1}
                    collapsed={this.state.topBatCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("topBatCollapsed")
                    }
                  />
                )}
              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded &&
                this.state.gameState.innings === 2 && (
                  <SimulatorTopBatDisplay
                    gameState={this.state.gameState}
                    latestResults={this.state.latestResults}
                    comparedResults={this.state.comparedResults}
                    comparedUserName={this.state.comparedUserName}
                    squad1={this.state.squad1}
                    squad2={this.state.squad2}
                    team1={this.state.team1}
                    team2={this.state.team2}
                    matchFormat={this.state.matchFormat}
                    loading={this.state.loading}
                    innings={2}
                    collapsed={this.state.topBatCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("topBatCollapsed")
                    }
                  />
                )}

              {this.state.gameState &&
                this.state.matchFormat &&
                this.state.matchFormat.inningsConfiguration.length === 2 &&
                this.state.gameState.initialStatsLoaded &&
                truePriceResults && (
                  <BallsInEachBracket
                    loading={this.state.loading}
                    collapsed={this.state.ballsInEachBracketCollapsed}
                    toggleCollapse={() =>
                      this.toggleCollapse("ballsInEachBracketCollapsed")
                    }
                    latestResult={truePriceResults[1]}
                    innings={this.state.gameState.innings}
                  />
                )}
            </div>
          </div>
          {this.state.gameState &&
            this.state.matchFormat &&
            this.state.matchFormat.inningsConfiguration.length === 2 &&
            !this.state.gameState.initialStatsLoaded && <StatsLoadingPage />}

          <SimulatorSettingsModal
            open={this.state.settingsModalOpen}
            onCancel={() => {
              this.setState({ settingsModalOpen: false });
            }}
            onProceed={(settings) => this.updatePreferences(settings)}
          />
        </div>
      </SimulatorRoute>
    );
  }
}
