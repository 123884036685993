import PersonIcon from "@mui/icons-material/Person";
import { Icon, Tooltip } from "@mui/material";
import { ReactNode } from "react";
import { Observable } from "rxjs";

import CricInfoLogo from "../img/cricinfo/favicon.png";
import { HistoricGroundFormUpdateMessage } from "../services/ground-stats-service";
import {
  HistoricPushMessage,
  PushBracketUpdateMessage,
} from "../services/historic-push-service";
import {
  HistoricPlayerFormUpdateMessage,
  HistoricStatsUpdateMessage,
} from "../services/player-stats-service";
import { Match } from "../types/entities/match";
import { Player } from "../types/entities/player";
import {
  BallCreationParameters,
  SimulatorScenario,
  UserPreferences,
} from "../types/preferences/preferences";
import { services } from "../types/services";
import { NodeHealth } from "../types/simulator/node-health";
import { SimulationResult } from "../types/simulator/simulation-result";

import { format, formatCurrency } from "./simulator-page/simulator-utils";

const MAX_COLOR_VALUE = 255;
const PERCENT_SCALE = 5.1;
const MID_PERCENT = 50;

export const buildChartOptions = (
  legendPosition: "top" | "bottom",
  yMax?: number
) => {
  return {
    responsive: true,
    scales: {
      yAxis: {
        max: yMax,
      },
    },
    plugins: {
      legend: {
        position: legendPosition,
      },
      title: {
        display: false,
      },
    },
  };
};

export function propsEqual(prevProps: any, newProps: any): boolean {
  let equal = true;
  Object.keys(newProps).forEach((key) => {
    if (prevProps[key] instanceof Map) {
      if (!mapsEqual(prevProps[key], newProps[key])) {
        equal = false;
      }
    } else {
      if (
        !(prevProps[key] instanceof Function) &&
        prevProps[key] !== newProps[key]
      ) {
        equal = false;
      }
    }
  });

  return equal;
}

export function levenshteinDistance(s, t): number {
  if (!s.length) return t.length;
  if (!t.length) return s.length;
  const arr = [];
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
            );
    }
  }

  return arr[t.length][s.length];
}

export function observableFrom(objects: any[]): Observable<any[]> {
  return new Observable((subscriber) => subscriber.next(objects));
}

export function getHomepageRoute(): string {
  const isUser = services.keycloakService.isUser();
  const isBetBuilder = services.keycloakService.isBetBuilder();
  const isInvestor = services.keycloakService.isInvestor();
  const isInvestorAdmin = services.keycloakService.isInvestorAdmin();
  const isAuthenticated = services.keycloakService.isAuthenticated();

  return isUser || isBetBuilder
    ? "recent-matches"
    : isInvestor
    ? "investments"
    : isInvestorAdmin
    ? "investment-admin"
    : isAuthenticated
    ? "no-roles"
    : "";
}

export function copyMap(src: Map<number, number>): Map<number, number> {
  if (!src) {
    return null;
  }

  const dest: Map<number, number> = new Map();
  Array.from(src).forEach(([x, y]) => dest.set(x, y));
  return dest;
}

export function sortMap(src: Map<number, number>): Map<number, number> {
  if (!src) {
    return null;
  }

  const dest: Map<number, number> = new Map();
  Array.from(src)
    .sort(([x1], [x2]) => (x1 > x2 ? 1 : -1))
    .forEach(([x, y]) => dest.set(x, y));
  return dest;
}

export const comparisonCellStyle = {
  backgroundColor: "#282c34",
  fontSize: "x-small",
  color: "white",
};

export function findScenarioResultsFromMap(
  scenario: SimulatorScenario,
  results: Map<string, [SimulatorScenario, SimulationResult]>
): [SimulatorScenario, SimulationResult] {
  for (const [simulatorScenario, simulationResult] of results.values()) {
    if (
      parametersEqual(
        simulatorScenario.ballCreationParameters,
        scenario.ballCreationParameters
      ) &&
      simulatorScenario.name === scenario.name
    ) {
      return [simulatorScenario, simulationResult];
    }
  }

  return [null, null];
}

function parametersEqual(
  params1: BallCreationParameters,
  params2: BallCreationParameters
) {
  if (!params1 && !params2) {
    return true;
  } else if (!params1 || !params2) {
    return false;
  } else {
    return (
      params1.runOut === params2.runOut &&
      params1.runs === params2.runs &&
      params1.toss === params2.toss &&
      params1.wicket1 === params2.wicket1 &&
      params1.wicket2 === params2.wicket2
    );
  }
}

export function getHalfTimeOddsConfidenceColour(value: number): string {
  const minConfidenceColour = "rgb(200, 0, 100)";

  if (!value && value !== 0) {
    return minConfidenceColour;
  }

  let weight = (Math.max(-1, Math.min(1, value)) + 1) / 2;
  const r = Math.floor(weight * 0 + (1 - weight) * 200);
  const g = Math.floor(weight * 200 + (1 - weight) * 0);
  const b = 100;

  return `rgb(${r}, ${g}, ${b})`;
}

export function compareValues(
  currentValue: number,
  comparedValue: number
): string {
  const currentNumber = Number(currentValue);
  const comparedNumber = Number(comparedValue);

  if (isNaN(currentNumber) || isNaN(comparedNumber)) {
    return "white";
  }

  if (
    currentNumber === null ||
    comparedNumber === null ||
    (currentNumber === 0 && comparedNumber === 0)
  ) {
    return "white";
  } else if (currentNumber === Infinity || comparedNumber === Infinity) {
    return "red";
  }

  const percent =
    Math.max(0, Math.min(2, comparedNumber / currentNumber)) * MID_PERCENT;
  let red = 0;
  let green = 0;
  let blue = 0;

  if (percent < MID_PERCENT) {
    red = MAX_COLOR_VALUE;
    green = Math.round(PERCENT_SCALE * percent);
  } else {
    green = MAX_COLOR_VALUE;
    red = Math.round(2 * MAX_COLOR_VALUE - PERCENT_SCALE * percent);
  }

  blue = MAX_COLOR_VALUE - Math.abs(green - red);

  return `rgb(${red}, ${green}, ${blue})`;
}

function mapsEqual(previousMap: Map<any, any>, newMap: Map<any, any>): boolean {
  const arr1: [number, any][] = Array.from(previousMap);
  const arr2: [number, any][] = Array.from(newMap);

  return (
    arr1.length === arr2.length &&
    arr1.every(([x, y], index) => {
      if (y instanceof Map) {
        return arr2[index][0] === x && mapsEqual(arr2[index][1], y);
      } else {
        return arr2[index][0] === x && arr2[index][1] === y;
      }
    })
  );
}

export function getDirection(holdings, investment) {
  if (holdings === investment) {
    return "";
  } else if (holdings > investment) {
    return (
      <div className="pot-increase">
        <Icon style={{ color: "green" }}>arrow_upward</Icon>
        <div>
          {formatCurrency(holdings - investment)}
          {investment === 0
            ? ")"
            : ` ${format((100 * holdings) / investment - 100)}%`}
        </div>
      </div>
    );
  } else if (holdings < investment) {
    return (
      <div className="pot-increase">
        <Icon style={{ color: "red" }}>arrow_downward</Icon>
        <div>
          {formatCurrency(holdings - investment)}
          {investment === 0
            ? ")"
            : ` ${format((100 * holdings) / investment - 100)}%`}
        </div>
      </div>
    );
  }
}

export function noSimulationsAllowed(
  match: Match,
  userPreferences: UserPreferences
): boolean {
  if (!match || !userPreferences) {
    return true;
  }

  return !match.simsEnabled || userPreferences.numberOfSimulations === 0;
}

export function noHealthyNodes(
  nodeHealth: NodeHealth[],
  latestVersion: string
) {
  if (!nodeHealth || !latestVersion) {
    return true;
  }

  if (nodeHealth.length === 0) {
    return true;
  }

  return nodeHealth.every(
    (node) => node.status === "Down" || node.version !== latestVersion
  );
}

export function calculateProgress(
  latestUpdate:
    | HistoricPushMessage
    | PushBracketUpdateMessage
    | HistoricGroundFormUpdateMessage
    | HistoricStatsUpdateMessage
): number {
  if (!latestUpdate) {
    return 0;
  }

  return (latestUpdate.done / latestUpdate.size) * 100;
}

export function calculateProgressMessage(
  latestUpdate:
    | HistoricPushMessage
    | PushBracketUpdateMessage
    | HistoricGroundFormUpdateMessage
    | HistoricStatsUpdateMessage
): string {
  if (!latestUpdate) {
    return "";
  }

  return latestUpdate.done + 1 + "/" + latestUpdate.size;
}

export function getLatestGroundMessage(
  latestUpdate: HistoricGroundFormUpdateMessage
): string {
  if (!latestUpdate) {
    return "";
  }

  return latestUpdate.message;
}

export function getLatestPlayerMessage(
  latestUpdate: HistoricPlayerFormUpdateMessage
): string {
  if (!latestUpdate) {
    return "";
  }

  return latestUpdate.lastPlayer;
}

export function getLogo(player: Player): ReactNode {
  if (player.espnNum) {
    return (
      <Tooltip title="From CRICINFO">
        {/* Used a PNG here, SVG was breaking and being weird,
        Sourced favicon from CricInfo, then ran through a ICO -> PNG Converter.
         */}
        <img
          src={CricInfoLogo}
          style={{
            marginRight: "5px",
            verticalAlign: "inherit",
            width: "1em",
            height: "1em",
            fontSize: "1rem",
          }}
          alt="cricinfo-logo"
        />
      </Tooltip>
    );
  } else if (player.createdBy) {
    return (
      <Tooltip title={"Created by user"}>
        <PersonIcon style={{ marginRight: "5px" }} />
      </Tooltip>
    );
  }

  return null;
}
