import { Icon } from "@mui/material";
import { useState } from "react";
import { PowerplayAdjustmentField } from "../../types/preferences/admin-preferences";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { powerplayPercentsExpansionButtonStyle } from "../admin-settings-page/match-specific-settings-component";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  PercentDistributionValue,
  PercentsDistributionBiasAdjuster,
} from "../stats-editing-components/percent-distribution-bias-adjuster";
import PercentDistributionComparison from "../stats-editing-components/percent-distribution-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: MatchStatsWrapper) => void;
}

type PowerplayAdjustmentsType =
  | "powerplayAdjustmentsInnings1"
  | "powerplayAdjustmentsInnings2";

export default function MatchStatsPowerplaySettingsComponent({
  currentStats,
  comparedStats,
  comparedUserName,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  const [powerplayPercentsExpanded, setPowerplayPercentsExpanded] =
    useState<boolean>(false);

  const onSteal = (updates: string[]) => {
    updates.forEach((update) => {
      updateStats(update, comparedStats.matchStats[update]);
    });
  };

  const updateStats = (property: string, value) => {
    const updatedStats: MatchStatsWrapper = currentStats;
    updatedStats.matchStats[property] = value;
    onUpdate(updatedStats);
  };

  function updatePowerplayAdjustment(
    field: PowerplayAdjustmentsType,
    powerplayAdjustmentsField: PowerplayAdjustmentField,
    value: number
  ) {
    currentStats.matchStats[field][powerplayAdjustmentsField] = value;
    onUpdate(currentStats);
  }

  function updateBias(
    field: PowerplayAdjustmentsType,
    property: PercentDistributionValue,
    value: number
  ) {
    currentStats.matchStats[field].powerplayPercentBiases[property] = value;
    onUpdate(currentStats);
  }

  const config: PowerplayAdjustmentsType[] = [
    "powerplayAdjustmentsInnings1",
    "powerplayAdjustmentsInnings2",
  ];

  return (
    <>
      {config.map((type, index) => (
        <div style={{ width: "50%" }} key={type}>
          <div style={{ padding: "2px" }}>Innings {index + 1}</div>
          <NumberSelector
            label={"Powerplay Push Adjustment"}
            min={-100}
            max={100}
            step={1}
            decimalPlaces={0}
            initial={currentStats.matchStats[type].basePushAdjustment}
            onValid={(valid: number) =>
              updatePowerplayAdjustment(type, "basePushAdjustment", valid)
            }
          />
          <NumberSelector
            label={"Powerplay Wicket Bias"}
            min={0}
            max={10}
            step={0.01}
            decimalPlaces={2}
            initial={currentStats.matchStats[type].baseWicketBias}
            onValid={(valid: number) =>
              updatePowerplayAdjustment(type, "baseWicketBias", valid)
            }
          />

          <button
            style={powerplayPercentsExpansionButtonStyle}
            onClick={() =>
              setPowerplayPercentsExpanded(!powerplayPercentsExpanded)
            }
          >
            <div>Powerplay Percent Bias</div>
            <Icon>
              {powerplayPercentsExpanded ? "expand_less" : "expand_more"}
            </Icon>
          </button>
          {powerplayPercentsExpanded && (
            <PercentsDistributionBiasAdjuster
              biases={currentStats.matchStats[type].powerplayPercentBiases}
              updateValue={(
                property: PercentDistributionValue,
                value: number
              ) => updateBias(type, property, value)}
            />
          )}

          {comparedStats && (
            <div className="comparison">
              <StealStatsHeadingAndButton comparedUserName={comparedUserName}>
                <StealStatsButton
                  comparedUserName={comparedUserName}
                  disabled={areStatsEqual(
                    currentStats,
                    comparedStats,
                    [type],
                    "matchStats"
                  )}
                  tooltipMessage={getToolTipMessage(
                    currentStats,
                    comparedStats,
                    [type],
                    comparedUserName,
                    "matchStats"
                  )}
                  onClickHandler={() => onSteal([type])}
                />
              </StealStatsHeadingAndButton>
              <NumberComparison
                label="Push Adjustment"
                originalValue={currentStats.matchStats[type].basePushAdjustment}
                comparedValue={
                  comparedStats.matchStats[type].basePushAdjustment
                }
                decimalPlaces={0}
              />
              <NumberComparison
                label="Wicket Bias"
                originalValue={currentStats.matchStats[type].baseWicketBias}
                comparedValue={comparedStats.matchStats[type].baseWicketBias}
              />
              <PercentDistributionComparison
                originalValue={
                  currentStats.matchStats[type].powerplayPercentBiases
                }
                comparedValue={
                  comparedStats.matchStats[type].powerplayPercentBiases
                }
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
}
