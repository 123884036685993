import { Country } from "../enums/country";
import { MatchType } from "../enums/match-type";
import { SinceDate } from "../enums/since-date";
import {
  deserializeGroundFormModules,
  GroundFormData,
  GroundFormModuleType,
  serializeGroundFormModules,
} from "../form/ground-form-modules";
import {
  deserializePlayerFormModules,
  PlayerFormData,
  PlayerFormModuleType,
  serializePlayerFormModules,
} from "../form/player-form-modules";
import {
  BattingAttackData,
  BattingAttackModuleType,
  deserializeBattingAttackModules,
  serializeBattingAttackModules,
} from "../simulator/modules/batting-attack-modules";
import {
  BowlingAttackData,
  BowlingAttackModuleType,
  deserializeBowlingAttackModules,
  serializeBowlingAttackModules,
} from "../simulator/modules/bowling-attack-modules";
import {
  deserializeExtrasModules,
  ExtrasData,
  ExtrasModuleType,
  serializeExtrasModules,
} from "../simulator/modules/extras-modules";
import {
  deserializeOutcomeAdjustModules,
  OutcomeAdjustData,
  OutcomeAdjustModuleType,
  serializeOutcomeAdjustModules,
} from "../simulator/modules/outcome-adjust-modules";
import {
  deserializePercentAdjustModules,
  PercentAdjustData,
  PercentAdjustModuleType,
  serializePercentAdjustModules,
} from "../simulator/modules/percent-adjust-modules";
import {
  deserializePushAdjustModules,
  PushAdjustData,
  PushAdjustModuleType,
  serializePushAdjustModules,
} from "../simulator/modules/push-adjust-modules";
import {
  deserializeStrikeRateAndWicketPercentModules,
  serializeStrikeRateAndWicketPercentModules,
  StrikeRateAndWicketPercentData,
  StrikeRateAndWicketPercentModuleType,
} from "../simulator/modules/strike-rate-and-wicket-percent-modules";
import { SimulationResult } from "../simulator/simulation-result";
import { UUID } from "../uuid";

export interface BallCreationParameters {
  wicket1: boolean;
  wicket2: boolean;
  runOut: boolean;
  runs: number;
  toss: number;
}

export function getTruePriceResults(
  latestResults: Map<string, [SimulatorScenario, SimulationResult]>
) {
  return !!latestResults && latestResults.get("default");
}

export const scenarioOrders: Record<string, number> = {
  "True Price": 0,
  "0": 1,
  "1": 2,
  "2": 3,
  "3": 4,
  "4": 5,
  "6": 6,
  W1: 7,
  W2: 8,
  "Team 1 Bat": 9,
  "Team 2 Bat": 10,
};

export interface SimulatorScenario {
  scenarioId: string;
  name: string;
  colour: string;
  ballCreationParameters: BallCreationParameters;
  enabled: boolean;
}

export interface MatchTypeConversionPreferences {
  source: MatchType;
  destination: MatchType;
  useMatchTypeConversion: boolean;
  matchTypeConversionWeight: number;
  matchTypeConversionPowerplayWeight: number;
}

export interface FormPreferences {
  playerRegressionValue: number;
  groundRegressionValue: number;
  ballsUntilHalfIn: number;
  ballsUntilIn: number;
  useCountryStatsInGroundFormCalculation: boolean;
  groundStatsInPlayerFormCalculationWeight: number;
  batsmanStatsInBowlerFormCalculationWeight: number;
  bowlerStatsInBatsmanFormCalculationWeight: number;
  matchTypeConversionPreferences: MatchTypeConversionPreferences[];
  allCountryWeights: number;
  countryWeights: Map<Country, number>;
  sinceDate: SinceDate;
  firstInningsWeight: number;
  secondInningsWeight: number;
  powerplayWeight: number;
  ballsForBattingExperience: number;
  ballsForBattingExperiencePowerplay: number;
  ballsForBattingExperienceNonPowerplay: number;
}

export interface UserPreferences {
  numberOfSimulations: number;
  numberOfRecordedSimulations: number;
  numberOfScenarioSimulations: number;
  runsLineLowerBound: number;
  runsLineUpperBound: number;
  runsLineMargin: number;
  runsLineStep: number;
  regressToCountryStats: boolean;
  extrasModules: Map<ExtrasModuleType, ExtrasData>;
  pushAdjustModules: Map<PushAdjustModuleType, PushAdjustData>;
  outcomeAdjustModules: Map<OutcomeAdjustModuleType, OutcomeAdjustData>;
  percentAdjustModules: Map<PercentAdjustModuleType, PercentAdjustData>;
  strikeRateAndWicketPercentModules: Map<
    StrikeRateAndWicketPercentModuleType,
    StrikeRateAndWicketPercentData
  >;
  battingAttackModules: Map<BattingAttackModuleType, BattingAttackData>;
  bowlingAttackModules: Map<BowlingAttackModuleType, BowlingAttackData>;
  playerFormModules: Map<PlayerFormModuleType, PlayerFormData>;
  groundFormModules: Map<GroundFormModuleType, GroundFormData>;
  formPreferences: FormPreferences;
  nodeConfigurations: UUID[];
  simulatorScenarios: SimulatorScenario[];
  randomGroundGlobalStrikeRateVariance: number;
  randomGroundGlobalWicketPercentVariance: number;
}

export function deserializeUserPreferences(json: any): UserPreferences {
  return {
    numberOfSimulations: json.numberOfSimulations,
    numberOfRecordedSimulations: json.numberOfRecordedSimulations,
    numberOfScenarioSimulations: json.numberOfScenarioSimulations,
    runsLineLowerBound: json.runsLineLowerBound,
    runsLineUpperBound: json.runsLineUpperBound,
    runsLineMargin: json.runsLineMargin,
    runsLineStep: json.runsLineStep,
    regressToCountryStats: json.regressToCountryStats,
    extrasModules: deserializeExtrasModules(json.extrasModules),
    pushAdjustModules: deserializePushAdjustModules(json.pushAdjustModules),
    percentAdjustModules: deserializePercentAdjustModules(
      json.percentAdjustModules
    ),
    outcomeAdjustModules: deserializeOutcomeAdjustModules(
      json.outcomeAdjustModules
    ),
    nodeConfigurations: json.nodeConfigurations as UUID[],
    strikeRateAndWicketPercentModules:
      deserializeStrikeRateAndWicketPercentModules(
        json.strikeRateAndWicketPercentModules
      ),
    battingAttackModules: deserializeBattingAttackModules(
      json.battingAttackModules
    ),
    bowlingAttackModules: deserializeBowlingAttackModules(
      json.bowlingAttackModules
    ),
    playerFormModules: deserializePlayerFormModules(json.playerFormModules),
    groundFormModules: deserializeGroundFormModules(json.groundFormModules),
    simulatorScenarios: json.simulatorScenarios,
    formPreferences: deserializeFormPreferences(json.formPreferences),
    randomGroundGlobalStrikeRateVariance:
      json.randomGroundGlobalStrikeRateVariance,
    randomGroundGlobalWicketPercentVariance:
      json.randomGroundGlobalWicketPercentVariance,
  };
}

export function serializeUserPreferences(
  userPreferences: UserPreferences
): any {
  return {
    numberOfSimulations: userPreferences.numberOfSimulations,
    numberOfRecordedSimulations: userPreferences.numberOfRecordedSimulations,
    numberOfScenarioSimulations: userPreferences.numberOfScenarioSimulations,
    runsLineLowerBound: userPreferences.runsLineLowerBound,
    runsLineUpperBound: userPreferences.runsLineUpperBound,
    runsLineMargin: userPreferences.runsLineMargin,
    runsLineStep: userPreferences.runsLineStep,
    regressToCountryStats: userPreferences.regressToCountryStats,
    extrasModules: serializeExtrasModules(userPreferences.extrasModules),
    pushAdjustModules: serializePushAdjustModules(
      userPreferences.pushAdjustModules
    ),
    percentAdjustModules: serializePercentAdjustModules(
      userPreferences.percentAdjustModules
    ),
    outcomeAdjustModules: serializeOutcomeAdjustModules(
      userPreferences.outcomeAdjustModules
    ),
    nodeConfigurations: userPreferences.nodeConfigurations,
    strikeRateAndWicketPercentModules:
      serializeStrikeRateAndWicketPercentModules(
        userPreferences.strikeRateAndWicketPercentModules
      ),
    battingAttackModules: serializeBattingAttackModules(
      userPreferences.battingAttackModules
    ),
    bowlingAttackModules: serializeBowlingAttackModules(
      userPreferences.bowlingAttackModules
    ),
    playerFormModules: serializePlayerFormModules(
      userPreferences.playerFormModules
    ),
    groundFormModules: serializeGroundFormModules(
      userPreferences.groundFormModules
    ),
    simulatorScenarios: userPreferences.simulatorScenarios,
    formPreferences: serializeFormPreferences(userPreferences.formPreferences),
    randomGroundGlobalStrikeRateVariance:
      userPreferences.randomGroundGlobalStrikeRateVariance,
    randomGroundGlobalWicketPercentVariance:
      userPreferences.randomGroundGlobalWicketPercentVariance,
  };
}

export function deserializeFormPreferences(
  formPreferences: any
): FormPreferences {
  return {
    playerRegressionValue: formPreferences.playerRegressionValue,
    groundRegressionValue: formPreferences.groundRegressionValue,
    ballsUntilHalfIn: formPreferences.ballsUntilHalfIn,
    ballsUntilIn: formPreferences.ballsUntilIn,
    useCountryStatsInGroundFormCalculation:
      formPreferences.useCountryStatsInGroundFormCalculation,
    groundStatsInPlayerFormCalculationWeight:
      formPreferences.groundStatsInPlayerFormCalculationWeight,
    batsmanStatsInBowlerFormCalculationWeight:
      formPreferences.batsmanStatsInBowlerFormCalculationWeight,
    bowlerStatsInBatsmanFormCalculationWeight:
      formPreferences.bowlerStatsInBatsmanFormCalculationWeight,
    matchTypeConversionPreferences: deserializeMatchTypeConversionPreferences(
      formPreferences.matchTypeConversionPreferences
    ),
    allCountryWeights: formPreferences.allCountryWeights,
    countryWeights: deserializeCountryDoubleMap(formPreferences.countryWeights),
    sinceDate: SinceDate[formPreferences.sinceDate],
    firstInningsWeight: formPreferences.firstInningsWeight,
    secondInningsWeight: formPreferences.secondInningsWeight,
    powerplayWeight: formPreferences.powerplayWeight,
    ballsForBattingExperience: formPreferences.ballsForBattingExperience,
    ballsForBattingExperiencePowerplay:
      formPreferences.ballsForBattingExperiencePowerplay,
    ballsForBattingExperienceNonPowerplay:
      formPreferences.ballsForBattingExperienceNonPowerplay,
  };
}

export function serializeFormPreferences(
  formPreferences: FormPreferences
): any {
  return {
    playerRegressionValue: formPreferences.playerRegressionValue,
    groundRegressionValue: formPreferences.groundRegressionValue,
    ballsUntilHalfIn: formPreferences.ballsUntilHalfIn,
    ballsUntilIn: formPreferences.ballsUntilIn,
    useCountryStatsInGroundFormCalculation:
      formPreferences.useCountryStatsInGroundFormCalculation,
    groundStatsInPlayerFormCalculationWeight:
      formPreferences.groundStatsInPlayerFormCalculationWeight,
    batsmanStatsInBowlerFormCalculationWeight:
      formPreferences.batsmanStatsInBowlerFormCalculationWeight,
    bowlerStatsInBatsmanFormCalculationWeight:
      formPreferences.bowlerStatsInBatsmanFormCalculationWeight,
    matchTypeConversionPreferences:
      formPreferences.matchTypeConversionPreferences,
    allCountryWeights: formPreferences.allCountryWeights,
    countryWeights: serializeMap(formPreferences.countryWeights),
    sinceDate: formPreferences.sinceDate,
    firstInningsWeight: formPreferences.firstInningsWeight,
    secondInningsWeight: formPreferences.secondInningsWeight,
    powerplayWeight: formPreferences.powerplayWeight,
    ballsForBattingExperience: formPreferences.ballsForBattingExperience,
    ballsForBattingExperiencePowerplay:
      formPreferences.ballsForBattingExperiencePowerplay,
    ballsForBattingExperienceNonPowerplay:
      formPreferences.ballsForBattingExperienceNonPowerplay,
  };
}

function deserializeMatchTypeConversionPreferences(
  matchTypeConversionPreferences: any[]
): MatchTypeConversionPreferences[] {
  return matchTypeConversionPreferences.map((preferences) => {
    return {
      source: MatchType[preferences.source],
      destination: MatchType[preferences.destination],
      useMatchTypeConversion: preferences.useMatchTypeConversion,
      matchTypeConversionWeight: preferences.matchTypeConversionWeight,
      matchTypeConversionPowerplayWeight:
        preferences.matchTypeConversionPowerplayWeight,
    };
  });
}

function deserializeCountryDoubleMap(json: any): Map<Country, number> {
  const map: Map<Country, number> = new Map();
  Object.keys(json).forEach((key) => {
    const value = Number(json[key]);
    map.set(Country[key], value);
  });
  return map;
}

function serializeMap(map: Map<any, any>): any {
  const result = {};
  map.forEach((value, key) => {
    result[key] = value;
  });
  return result;
}
