import { Player } from "../../../types/entities/player";
import { Squad } from "../../../types/entities/squad";
import { SimulatorRoute } from "../../../types/route-helpers";
import { services } from "../../../types/services";
import { MatchStatsWrapper } from "../../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../../types/stats/player-stats";
import { format } from "../../simulator-page/simulator-utils";

import NumberSelector from "./number-selector";

export type PlayerStrikeRateBiasProperty =
  | "battingPowerplayStrikeRateBias"
  | "battingNonPowerplayStrikeRateBias"
  | "battingGlobalStrikeRateBias"
  | "bowlingGlobalStrikeRateBias";

export type PlayerWicketBiasProperty =
  | "battingPowerplayWicketBias"
  | "battingNonPowerplayWicketBias"
  | "battingGlobalWicketBias"
  | "bowlingGlobalWicketBias";

export type PlayerStrikeRateAndWicketBiasProperties =
  | PlayerStrikeRateBiasProperty
  | PlayerWicketBiasProperty;

export type MatchStrikeRateBiasProperty =
  | "batsmanMatchStrikeRateAdjustments"
  | "bowlerMatchStrikeRateAdjustments";

export type MatchWicketRateBiasProperty =
  | "batsmanMatchWicketPercentAdjustments"
  | "bowlerMatchWicketPercentAdjustments";

interface Props {
  player: Player;
  onSpinnerEdit: (editing: boolean) => void;
  textColour: string;
  primaryColour: string;
  secondaryColour: string;
  tableId: string;
  playerStrikeRateBiasProperty: PlayerStrikeRateBiasProperty;
  playerWicketBiasProperty: PlayerWicketBiasProperty;
  matchStrikeRateBiasProperty: MatchStrikeRateBiasProperty;
  matchWicketBiasProperty: MatchWicketRateBiasProperty;
  matchStats: MatchStatsWrapper;
  playerStats: Map<string, PlayerStatsWrapper>;
  squad: Squad;
  isBowler?: boolean;
}

export const TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MIN = -10;
export const TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MAX = 10;
export const TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_STEP = 0.01;
export const TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_DP = 2;

export const TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MIN = -100;
export const TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MAX = 100;
export const TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_STEP = 1;
export const TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_DP = 0;

export default function PlayerSelectorGlobalBiasesComponent({
  player,
  onSpinnerEdit,
  textColour,
  primaryColour,
  secondaryColour,
  tableId,
  playerStrikeRateBiasProperty,
  playerWicketBiasProperty,
  matchStrikeRateBiasProperty,
  matchWicketBiasProperty,
  matchStats,
  playerStats,
  squad,
  isBowler = false,
}: Readonly<Props>): React.JSX.Element {
  const updateMatchStats = (
    property: string,
    teamId: string,
    playerId: string,
    value: number
  ) => {
    const newMatchStats = MatchStatsWrapper.clone(matchStats);
    newMatchStats.matchStats[property]?.get(teamId).set(playerId, value);
    services.matchStatsService.updateMatchStats(newMatchStats);
  };

  const getSpinnerLabel = (label: string, matchAdjustment: number) => {
    if (matchAdjustment !== 0) {
      label =
        label + " (" + (matchAdjustment > 0 ? "+" : "") + matchAdjustment + ")";
    }
    return label;
  };

  const globalStrikeRateBias = playerStats?.get(player?.playerId.value)
    ?.playerStats[playerStrikeRateBiasProperty];

  const globalWicketBias = playerStats?.get(player?.playerId.value)
    ?.playerStats[playerWicketBiasProperty];

  const getAdjustment = (property: string) => {
    const squadAdjustments =
      matchStats?.matchStats[property].get(squad?.teamId.value) || new Map();
    const playerAdjustment = squadAdjustments.get(player?.playerId.value) || 0;
    return Number(format(playerAdjustment));
  };

  const matchStrikeRateAdjustment = getAdjustment(matchStrikeRateBiasProperty);
  const matchWicketAdjustment = getAdjustment(matchWicketBiasProperty);
  const matchPushAdjustment = isBowler
    ? getAdjustment("bowlerMatchPushAdjustments")
    : 0;

  return (
    <SimulatorRoute>
      {matchStats && playerStats && squad && player && (
        <tr id={tableId} style={{ color: textColour, fontSize: "x-small" }}>
          <td style={{ paddingLeft: "1em" }}>Global Biases</td>
          <td colSpan={3}>
            <NumberSelector
              label={getSpinnerLabel("SR", matchStrikeRateAdjustment)}
              min={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MIN}
              max={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MAX}
              step={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_STEP}
              decimalPlaces={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_DP}
              initial={globalStrikeRateBias + matchStrikeRateAdjustment}
              onValid={(value: number) =>
                updateMatchStats(
                  matchStrikeRateBiasProperty,
                  squad.teamId.value,
                  player.playerId.value,
                  value - globalStrikeRateBias
                )
              }
              onManualEdit={() => onSpinnerEdit(true)}
              onManualEditFinished={() => onSpinnerEdit(false)}
              componentsStyle={{
                backgroundColor: secondaryColour,
                border: "1px solid " + secondaryColour,
              }}
              textFieldStyle={{
                backgroundColor: primaryColour,
                color: textColour,
                borderWidth: "0px",
                height: "20px",
              }}
              buttonStyle={{
                maxHeight: "9.5px",
                minHeight: "9.5px",
                color: textColour,
              }}
            />
          </td>
          <td colSpan={3}>
            <NumberSelector
              label={getSpinnerLabel("W%", matchWicketAdjustment)}
              min={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MIN}
              max={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MAX}
              step={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_STEP}
              decimalPlaces={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_DP}
              initial={globalWicketBias + matchWicketAdjustment}
              onValid={(value: number) =>
                updateMatchStats(
                  matchWicketBiasProperty,
                  squad.teamId.value,
                  player.playerId.value,
                  value - globalWicketBias
                )
              }
              onManualEdit={() => onSpinnerEdit(true)}
              onManualEditFinished={() => onSpinnerEdit(false)}
              componentsStyle={{
                backgroundColor: secondaryColour,
                border: "1px solid " + secondaryColour,
              }}
              textFieldStyle={{
                backgroundColor: primaryColour,
                color: textColour,
                borderWidth: "0px",
                height: "20px",
              }}
              buttonStyle={{
                maxHeight: "9.5px",
                minHeight: "9.5px",
                color: textColour,
              }}
            />
          </td>
          {isBowler && (
            <td colSpan={3}>
              <NumberSelector
                label={"Push"}
                min={TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MIN}
                max={TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MAX}
                step={TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_STEP}
                decimalPlaces={TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_DP}
                initial={matchPushAdjustment}
                onValid={(value: number) => {
                  updateMatchStats(
                    "bowlerMatchPushAdjustments",
                    squad.teamId.value,
                    player.playerId.value,
                    value
                  );
                }}
                onManualEdit={() => onSpinnerEdit(true)}
                onManualEditFinished={() => onSpinnerEdit(false)}
                componentsStyle={{
                  backgroundColor: secondaryColour,
                  border: "1px solid " + secondaryColour,
                }}
                textFieldStyle={{
                  backgroundColor: primaryColour,
                  color: textColour,
                  borderWidth: "0px",
                  height: "20px",
                }}
                buttonStyle={{
                  maxHeight: "9.5px",
                  minHeight: "9.5px",
                  color: textColour,
                }}
              />
            </td>
          )}
        </tr>
      )}
    </SimulatorRoute>
  );
}
