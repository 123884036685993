import React from "react";
import { TrafficLight } from "../../types/enums/traffic-light";
import { format } from "../simulator-page/simulator-utils";
import TrafficLightDisplay from "../stats-editing-components/traffic-light-display";

interface Props {
  odds: number;
  edge: number;
  confidence: TrafficLight;
}

export function StoredQuestionOddsDisplay({
  odds,
  edge,
  confidence,
}: Props): React.JSX.Element {
  const oddsUnavailable = odds === null || odds === undefined;

  function adjustOddsByPercent(odds: number, delta: number) {
    return !oddsUnavailable && 1 / ((1 / odds) * (1 + delta));
  }

  function formatOdds(odds: number) {
    if (!Number.isFinite(odds)) {
      return "∞";
    }
    if (odds < 1) {
      return "";
    }
    return format(odds);
  }

  const delta = edge / 100;
  const backOdds = adjustOddsByPercent(odds, delta);
  const layOdds = adjustOddsByPercent(odds, -delta);

  return (
    <div
      className="header"
      style={{
        borderTop: "1px solid lightgrey",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <div>Odds:</div>
      {oddsUnavailable && <div style={{ padding: "10px" }}>Unavailable</div>}
      {!oddsUnavailable && (
        <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              width: "400px",
            }}
          >
            <div className="betfair-back">{formatOdds(backOdds)}</div>
            <div className="betfair-true">{formatOdds(odds)}</div>
            <div className="betfair-lay">{formatOdds(layOdds)}</div>
          </div>
          {confidence && <TrafficLightDisplay value={confidence} />}
        </div>
      )}
    </div>
  );
}
