import { Button } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import { MatchTypeSkew } from "../../services/historic-push-service";
import { MatchType } from "../../types/enums/match-type";
import { PushBracket } from "../../types/enums/push-bracket";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import { EmployeeRoute } from "../../types/route-helpers";
import { services } from "../../types/services";

import MultiStageUpdateProgressComponent from "../default-player-stats-page/multi-stage-update-progress-component";
import { HistoricEventTable } from "./historic-event-table";
import { MatchTypeSkewsDisplay } from "./match-type-skews-display";
import { PushBracketDisplay } from "./push-bracket-display";
import { ScrapedMatchFilter } from "./scraped-match-filter";

interface State {
  generatingHistoricPush: boolean;
  generatingPushBrackets: boolean;
  pushBrackets: Map<MatchType, [PushBracket, number][]>;
  matchTypeSkews: Map<MatchType, Map<MatchType, MatchTypeSkew>>;
  adminPreferences: AdminPreferences;
  loading: boolean;
}

export class HistoricMatchesPage extends Component<{}, State> {
  private subscriptions: Subscription[] = [];
  private static readonly DEFAULT_STATE = {
    generatingHistoricPush: false,
    generatingPushBrackets: false,
    pushBrackets: null,
    matchTypeSkews: null,
    adminPreferences: null,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state = HistoricMatchesPage.DEFAULT_STATE;
  }

  componentDidMount() {
    this.subscriptions.push(
      services.historicPushService.pushBracketsSubject.subscribe(
        (update: Map<MatchType, [PushBracket, number][]>) => {
          this.setState({ pushBrackets: update });
        }
      ),

      services.historicPushService.matchTypeSkewSubject.subscribe(
        (update: Map<MatchType, Map<MatchType, MatchTypeSkew>>) => {
          this.setState({ matchTypeSkews: update });
        }
      ),

      services.userService.adminPreferencesSubject.subscribe(
        (update: AdminPreferences) => {
          this.setState({ adminPreferences: update });
        }
      ),

      services.multipleStageUpdateService.loadingSubject.subscribe(
        (loading: boolean) => {
          this.setState({ loading });
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  public render() {
    return (
      <EmployeeRoute>
        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Scraped Matches</div>
            <div className="squad-buttons">
              <Button
                onClick={() => {
                  services.multipleStageUpdateService.recalculateAllRelatedStats();
                }}
                variant={"contained"}
                color="primary"
                disabled={this.state.loading}
              >
                Recalculate all historic stats
              </Button>
            </div>
          </div>
          {!this.state.loading && (
            <div className="historic-matches-content">
              {!!this.state.pushBrackets && (
                <PushBracketDisplay pushBrackets={this.state.pushBrackets} />
              )}
              {!!this.state.matchTypeSkews && (
                <MatchTypeSkewsDisplay
                  adminPreferences={this.state.adminPreferences}
                  matchTypeSkews={this.state.matchTypeSkews}
                />
              )}
              <ScrapedMatchFilter />
              <HistoricEventTable />
            </div>
          )}

          {this.state.loading && <MultiStageUpdateProgressComponent />}
        </div>
      </EmployeeRoute>
    );
  }
}
