import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component, ReactNode } from "react";
import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Team } from "../../types/entities/team";
import {
  getTruePriceResults,
  SimulatorScenario,
  UserPreferences,
} from "../../types/preferences/preferences";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import {
  compareValues,
  comparisonCellStyle,
  findScenarioResultsFromMap,
} from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import TrafficLightDisplay from "../stats-editing-components/traffic-light-display";
import {
  format,
  getTeamName,
  hexToGreyscale,
  OddsTableHeaderRow,
  sortResults,
} from "./simulator-utils";
import { TeamInningsProgressionModal } from "./team-innings-progression-modal";

interface Props {
  gameState: GameState;
  matchFormat: MatchFormat;
  latestResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedMatchStats: MatchStatsWrapper;
  team1: Team;
  team2: Team;
  match: Match;
  loading: boolean;
  collapsed: boolean;
  userPreferences: UserPreferences;
  onScenarioUpdate: (scenario: SimulatorScenario, enabled: boolean) => void;
  toggleCollapse: () => void;
}

interface State {
  currentTeam: number;
  teamInningsProgressionModalOpen: boolean;
}

export class SimulatorMatchOddsDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentTeam: 0,
      teamInningsProgressionModalOpen: false,
    };
  }

  private buildTableCell(
    scenario: SimulatorScenario,
    team: number,
    probability: number,
    comparedProbability: number
  ) {
    const style = !!comparedProbability
      ? {
          ...comparisonCellStyle,
          color: compareValues(probability, comparedProbability),
        }
      : {
          backgroundColor: scenario.enabled
            ? scenario.colour
            : hexToGreyscale(scenario.colour),
        };

    return (
      <TableCell
        key={`match-odds-${scenario.name}-team${team}${
          !!comparedProbability ? "-comparison" : ""
        }`}
        align="right"
        style={style}
        id={"simulation-table-cell"}
      >
        {format(probability)}
      </TableCell>
    );
  }

  private buildTableRow(
    cells: ReactNode[],
    teamName: string,
    team: number,
    comparisonRow: boolean
  ) {
    return (
      <TableRow
        key={`team-${team}-match-odds${comparisonRow ? "-comparison" : ""}`}
      >
        <TableCell
          scope="row"
          style={comparisonRow ? comparisonCellStyle : null}
        >
          <div className="match-odds-team-name-and-button">
            {comparisonRow ? (
              <TrafficLightDisplay
                value={
                  this.props.comparedMatchStats?.matchStats?.traderConfidence
                }
              />
            ) : (
              teamName
            )}
            {!comparisonRow && team > 0 && (
              <TooltipIconButton
                title="Innings progression"
                onClick={() =>
                  this.setState({
                    teamInningsProgressionModalOpen: true,
                    currentTeam: team - 1,
                  })
                }
                icon="ssid_chart"
              />
            )}
          </div>
        </TableCell>
        {cells}
      </TableRow>
    );
  }

  public render() {
    const team1Values: ReactNode[] = [];
    const team2Values: ReactNode[] = [];
    const drawValues: ReactNode[] = [];
    const team1ComparisonValues: ReactNode[] = [];
    const team2ComparisonValues: ReactNode[] = [];
    const drawComparisonValues: ReactNode[] = [];

    const truePriceResults =
      !!this.props.latestResults &&
      getTruePriceResults(this.props.latestResults);

    const comparedTruePriceResults =
      !!this.props.comparedResults &&
      getTruePriceResults(this.props.comparedResults);

    let sortedResults: Map<string, [SimulatorScenario, SimulationResult]>;

    if (!!truePriceResults) {
      sortedResults = sortResults(this.props.latestResults);

      sortedResults.forEach(([scenario, simulationResult], scenarioId) => {
        const team1WinsProb =
          1 /
          (simulationResult.team1Wins /
            (simulationResult.team1Wins + simulationResult.team2Wins));
        const team2WinsProb =
          1 /
          (simulationResult.team2Wins /
            (simulationResult.team1Wins + simulationResult.team2Wins));
        const drawsProb =
          1 / (simulationResult.draws / simulationResult.numberOfSimulations);

        const comparedResults =
          !!this.props.comparedResults &&
          findScenarioResultsFromMap(scenario, this.props.comparedResults)[1];
        const team1WinsProbComparison =
          !!comparedResults &&
          1 /
            (comparedResults.team1Wins /
              (comparedResults.team1Wins + comparedResults.team2Wins));
        const team2WinsProbComparison =
          !!comparedResults &&
          1 /
            (comparedResults.team2Wins /
              (comparedResults.team1Wins + comparedResults.team2Wins));
        const drawsProbComparison =
          !!comparedResults &&
          1 / (comparedResults.draws / comparedResults.numberOfSimulations);

        team1Values.push(this.buildTableCell(scenario, 1, team1WinsProb, null));
        team2Values.push(this.buildTableCell(scenario, 2, team2WinsProb, null));
        drawValues.push(this.buildTableCell(scenario, 0, drawsProb, null));
        team1ComparisonValues.push(
          this.buildTableCell(
            scenario,
            1,
            team1WinsProbComparison,
            team1WinsProb
          )
        );
        team2ComparisonValues.push(
          this.buildTableCell(
            scenario,
            2,
            team2WinsProbComparison,
            team2WinsProb
          )
        );
        drawComparisonValues.push(
          this.buildTableCell(scenario, 0, drawsProbComparison, drawsProb)
        );
      });
    }

    const team1Name = getTeamName(
      1,
      !!truePriceResults && truePriceResults[1],
      this.props.team1,
      this.props.team2
    );
    const team2Name = getTeamName(
      2,
      !!truePriceResults && truePriceResults[1],
      this.props.team1,
      this.props.team2
    );

    const team1Row = this.buildTableRow(team1Values, team1Name, 1, false);
    const team2Row = this.buildTableRow(team2Values, team2Name, 2, false);
    const drawsRow = this.buildTableRow(drawValues, "Draw", 0, false);
    const team1ComparisonRow = this.buildTableRow(
      team1ComparisonValues,
      team1Name,
      1,
      true
    );
    const team2ComparisonRow = this.buildTableRow(
      team2ComparisonValues,
      team2Name,
      2,
      true
    );
    const drawsComparisonRow = this.buildTableRow(
      drawComparisonValues,
      "Draw",
      0,
      true
    );

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Match Odds
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {this.props.latestResults && !this.props.collapsed && (
          <LoadingOverlay
            active={this.props.loading}
            spinner
            text="Simulating..."
          >
            <TableContainer>
              <Table
                aria-label="simulator match odds table"
                size="small"
                stickyHeader
              >
                <TableHead>
                  {sortedResults && (
                    <OddsTableHeaderRow
                      sortedResults={sortedResults}
                      truePriceResults={truePriceResults[1]}
                      team1={this.props.team1}
                      team2={this.props.team2}
                      title={"Selection"}
                      tableName={"match-odds"}
                      enableSort={false}
                      showCheckboxes={true}
                      onScenarioUpdate={(scenario, enabled) =>
                        this.props.onScenarioUpdate(scenario, enabled)
                      }
                      userPreferences={this.props.userPreferences}
                    />
                  )}
                </TableHead>
                <TableBody>
                  {team1Row}
                  {this.props.comparedResults.size > 0 && team1ComparisonRow}
                  {team2Row}
                  {this.props.comparedResults.size > 0 && team2ComparisonRow}
                  {drawsRow}
                  {this.props.comparedResults.size > 0 && drawsComparisonRow}
                </TableBody>
              </Table>
            </TableContainer>
            <TeamInningsProgressionModal
              open={this.state.teamInningsProgressionModalOpen}
              onProceed={() =>
                this.setState({
                  teamInningsProgressionModalOpen: false,
                  currentTeam: 0,
                })
              }
              initialTeamNumber={this.state.currentTeam}
              team1={this.props.team1}
              team2={this.props.team2}
              gameState={this.props.gameState}
              matchFormat={this.props.matchFormat}
              simulationResult={truePriceResults && truePriceResults[1]}
              comparedResult={
                comparedTruePriceResults && comparedTruePriceResults[1]
              }
              match={this.props.match}
            />
          </LoadingOverlay>
        )}
      </div>
    );
  }
}
