import { GroundStats } from "../../types/stats/ground-stats";

import { format } from "../simulator-page/simulator-utils";

type StrikeRateProperty =
  | "globalStrikeRateBias"
  | "powerplayStrikeRateBias"
  | "nonPowerplayStrikeRateBias"
  | "paceStrikeRateBias"
  | "spinStrikeRateBias";

type WicketPercentProperty =
  | "globalWicketBias"
  | "powerplayWicketBias"
  | "nonPowerplayWicketBias"
  | "paceWicketBias"
  | "spinWicketBias";

type ConfidenceProperty =
  | "globalConfidence"
  | "powerplayConfidence"
  | "nonPowerplayConfidence"
  | "paceConfidence"
  | "spinConfidence";

interface Props {
  groundStats: GroundStats;
  title: string;
  strikeRateProperty: StrikeRateProperty;
  wicketPercentProperty: WicketPercentProperty;
  confidenceProperty: ConfidenceProperty;
}

export default function HistoricGroundStrikeRateAndWicketPercentStats({
  groundStats,
  title,
  strikeRateProperty,
  wicketPercentProperty,
  confidenceProperty,
}: Readonly<Props>): React.JSX.Element {
  if (!groundStats) {
    return null;
  }

  const labelsAndProperties = [
    { label: "Strike Rate", property: strikeRateProperty },
    { label: "Wicket Percent", property: wicketPercentProperty },
    { label: "Confidence", property: confidenceProperty },
  ];

  return (
    <div className="stats-modal-section">
      <div>
        <div className="stats-modal-section-head">{title}</div>
        <div className="historic-stats-uneditable">
          {labelsAndProperties.map(({ label, property }) => (
            <div className="historic-stat-uneditable" key={property}>
              <div>{label}</div>
              <div>{format(groundStats[property])}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
