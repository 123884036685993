import React from "react";

import { Squad } from "../../types/entities/squad";
import { playerSimulationQuestionStatisticNames } from "../../types/enums/question-statistic";
import { operatorNames } from "../../types/enums/questions-operator";
import { services } from "../../types/services";
import {
  PlayerSimulationOddsQuestion,
  SimulationOddsQuestion,
  SimulationQuestionType,
  questionTypesEnumToString,
  questionTypesStringToEnum,
  simulationQuestionTypeNames,
} from "../../types/simulator/questions/simulation-odds-question";
import { createDefaultQuestionOfType } from "../../types/simulator/questions/stored-questions";
import { UUID } from "../../types/uuid";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { format } from "../simulator-page/simulator-utils";

import { TrafficLight } from "../../types/enums/traffic-light";
import PlayerSimulatorOddsQuestionEditor from "./simulator-player-odds-question-editor";
import { StoredQuestionOddsDisplay } from "./stored-question-odds-display";

interface PlayerProps {
  storedQuestionId: UUID;
  question: PlayerSimulationOddsQuestion;
  editing: boolean;
  squad1: Squad;
  squad2: Squad;
  standalone?: boolean;
  odds: number;
  edge: number;
  confidence?: TrafficLight;
  onUpdate: (question: SimulationOddsQuestion) => void;
  setEditing: (editing: boolean) => void;
  onDelete: (storedQuestionId: UUID) => void;
}

export default function PlayerStoredQuestionDisplay({
  storedQuestionId,
  question,
  editing,
  squad1,
  squad2,
  standalone = true,
  odds,
  edge,
  confidence = null,
  onUpdate,
  setEditing,
  onDelete,
}: PlayerProps): React.JSX.Element {
  const playerInSquad1: boolean =
    squad1 &&
    squad1.players &&
    squad1.players.find((p) => p.playerId.value === question.playerId) !==
      undefined;
  const playerLabel = services.teamPlayerService.getPlayerName(
    UUID.fromString(question.playerId),
    playerInSquad1 ? squad1 : squad2
  );
  return (
    <div
      className="contained column"
      style={{
        padding: "0px",
        gap: "10px",
      }}
    >
      {standalone && (
        <div
          className="header stored-question-header"
          style={{
            backgroundColor: "rgba(100, 300, 200, 0.5)",
          }}
        >
          <div>Player Question</div>
          <div className="stored-question-buttons">
            <TooltipIconButton
              title={editing ? "Save" : "Edit"}
              icon={editing ? "save_circle" : "edit_circle"}
              onClick={() => setEditing(!editing)}
            />
            <TooltipIconButton
              title={"Delete"}
              icon={"delete_circle"}
              onClick={() => onDelete(storedQuestionId)}
            />
          </div>
        </div>
      )}
      <div
        className={
          editing
            ? "editing-stored-question-display"
            : "non-editing-stored-question-display"
        }
      >
        {!editing && (
          <>
            <div>
              {playerLabel}{" "}
              {
                playerSimulationQuestionStatisticNames[
                  question.questionStatistic
                ]
              }{" "}
              {operatorNames[question.operator]} {format(question.operand)}
            </div>
          </>
        )}
        {editing && (
          <>
            <EnumSelector
              classes="dialog-content"
              enumObject={SimulationQuestionType}
              label="Type"
              value={questionTypesStringToEnum[question.subType]}
              readableValues={simulationQuestionTypeNames}
              onSelect={(value) => {
                if (question.subType !== questionTypesEnumToString[value]) {
                  onUpdate(createDefaultQuestionOfType(value, squad1));
                }
              }}
              disabled={false}
              canClear={false}
              unavailable={[SimulationQuestionType.MULTI]}
            />
            <PlayerSimulatorOddsQuestionEditor
              squad1={squad1}
              squad2={squad2}
              question={question}
              onChange={onUpdate}
            />
            {!standalone && (
              <TooltipIconButton
                title={"Delete"}
                icon={"delete_circle"}
                onClick={() => onDelete(storedQuestionId)}
              />
            )}
          </>
        )}
      </div>
      {standalone && (
        <StoredQuestionOddsDisplay
          odds={odds}
          edge={edge}
          confidence={confidence}
        />
      )}
    </div>
  );
}
