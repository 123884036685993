import { MarketType } from "../../types/betfair/betfair-message";
import { MatchFormat } from "../../types/entities/match-format";

function get6OverRunsLineMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.INNINGS_1_6_OVER_LINE];
  }

  return [MarketType.INNINGS_2_6_OVER_LINE];
}

function get10OverRunsLineMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.INNINGS_1_10_OVER_LINE];
  }

  return [MarketType.INNINGS_2_10_OVER_LINE];
}

function get20OverRunsLineMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.INNINGS_1_20_OVER_LINE];
  }

  return [MarketType.INNINGS_2_20_OVER_LINE];
}

function get50OverRunsLineMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.INNINGS_1_50_OVER_LINE];
  }

  return [MarketType.INNINGS_2_50_OVER_LINE];
}

function getHundredMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [
      MarketType.INNINGS_1_25_BALL_LINE,
      MarketType.INNINGS_1_100_BALL_LINE,
    ];
  }

  return [
    MarketType.INNINGS_2_25_BALL_LINE,
    MarketType.INNINGS_2_100_BALL_LINE,
  ];
}

function getNonHundredMarkets(
  innings: number,
  oversThisInnings: number
): MarketType[] {
  let marketTypes: MarketType[] = [];

  if (oversThisInnings >= 6) {
    marketTypes = [...marketTypes, ...get6OverRunsLineMarkets(innings)];
  }

  if (oversThisInnings >= 10) {
    marketTypes = [...marketTypes, ...get10OverRunsLineMarkets(innings)];
  }

  if (oversThisInnings >= 20) {
    marketTypes = [...marketTypes, ...get20OverRunsLineMarkets(innings)];
  }

  if (oversThisInnings >= 50) {
    marketTypes = [...marketTypes, ...get50OverRunsLineMarkets(innings)];
  }

  return marketTypes;
}

export function getMarketsForMatchFormat(
  matchFormat: MatchFormat,
  innings: number
): MarketType[] {
  // Always include MATCH_ODDS
  const marketTypes: MarketType[] = [MarketType.MATCH_ODDS];

  const totalBallsInInnings = MatchFormat.getTotalBallsInInnings(
    innings,
    matchFormat
  );

  if (totalBallsInInnings === 100) {
    marketTypes.push(...getHundredMarkets(innings));
  } else {
    const oversThisInnings = matchFormat.overConfiguration[innings - 1].length;
    marketTypes.push(...getNonHundredMarkets(innings, oversThisInnings));
  }

  return marketTypes;
}
