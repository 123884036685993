import { PercentDistributionBiasData } from "../../types/stats/ground-stats";
import { NumberComparison } from "./number-comparison";

interface Props {
  originalValue: PercentDistributionBiasData;
  comparedValue: PercentDistributionBiasData;
}

export default function MatchStatsPowerplaySettingsComponent({
  originalValue,
  comparedValue,
}: Readonly<Props>): React.JSX.Element {
  return (
    <>
      <NumberComparison
        label="Boundary to Runs Bias"
        originalValue={originalValue.boundaryToRunsBias}
        comparedValue={comparedValue.boundaryToRunsBias}
      />
      <NumberComparison
        label="Ones to Twos Bias"
        originalValue={originalValue.oneToTwoBias}
        comparedValue={comparedValue.oneToTwoBias}
      />
      <NumberComparison
        label="Fours to Sixes Bias"
        originalValue={originalValue.fourToSixBias}
        comparedValue={comparedValue.fourToSixBias}
      />
      <NumberComparison
        label="Ones and Twos to Threes Bias"
        originalValue={originalValue.oneAndTwoToThreeBias}
        comparedValue={comparedValue.oneAndTwoToThreeBias}
      />
    </>
  );
}
