import { CSSProperties } from "react";
import {
  TrafficLight,
  trafficLightColours,
} from "../../types/enums/traffic-light";

interface Props {
  value?: TrafficLight;
  lampRadiusPx?: number;
  paddingPx?: number;
  onChange?: (trafficLight: TrafficLight) => void;
}

export default function TrafficLightDisplay({
  value = null,
  lampRadiusPx = 10,
  paddingPx = 4,
  onChange = null,
}: Readonly<Props>): React.JSX.Element {
  const maxWidth = paddingPx * 4 + lampRadiusPx * 3;
  const trafficLightContainerStyle: CSSProperties = {
    backgroundColor: "#000",
    borderRadius: paddingPx + "px",
    display: "flex",
    padding: paddingPx + "px",
    gap: paddingPx + "px",
    maxWidth: maxWidth,
  };

  const trafficLightStyle: CSSProperties = {
    backgroundColor: "#555",
    borderRadius: lampRadiusPx + "px",
    height: lampRadiusPx + "px",
    width: lampRadiusPx + "px",
    border: "none",
    padding: "0px",
  };

  return (
    <>
      {value && (
        <div style={trafficLightContainerStyle}>
          {Object.keys(TrafficLight).map((light) => (
            <button
              key={light}
              style={{
                ...trafficLightStyle,
                backgroundColor:
                  light === value ? trafficLightColours[value] : "#555",
              }}
              disabled={!onChange}
              onClick={() => onChange && onChange(TrafficLight[light])}
            />
          ))}
        </div>
      )}
    </>
  );
}
