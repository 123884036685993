import { Squad } from "../../../types/entities/squad";
import { services } from "../../../types/services";
import { MatchStatsWrapper } from "../../../types/stats/match-stats";
import { UUID } from "../../../types/uuid";
import NumberSelector from "../../entity-management/entity-selectors/number-selector";
import { getTitleColour } from "../game-state-display/tsbc";

type Props = {
  primaryColour: string;
  secondaryColour: string;
  matchStats: MatchStatsWrapper;
  batsman1Id: UUID;
  batsman2Id: UUID;
  squad: Squad;
  onSpinnerEdit: (editing: boolean) => void;
};

export function PartnershipAdjustments({
  primaryColour,
  secondaryColour,
  matchStats,
  batsman1Id,
  batsman2Id,
  squad,
  onSpinnerEdit,
}: Readonly<Props>): React.JSX.Element {
  const PARTNERSHIP_ADJUSTMENT_MIN = -100;
  const PARTNERSHIP_ADJUSTMENT_MAX = 100;
  const PARTNERSHIP_ADJUSTMENT_STEP = 1;
  const PARTNERSHIP_ADJUSTMENT_DP = 0;

  const textColour: string = getTitleColour(primaryColour);
  const currentTeamAdjustments =
    squad &&
    matchStats?.matchStats.partnershipPushAdjustments.get(squad.teamId.value);

  const potentialEntry1 = `${batsman1Id?.value},${batsman2Id?.value}`;
  const potentialEntry2 = `${batsman2Id?.value},${batsman1Id?.value}`;

  const currentPartnershipAdjustment =
    currentTeamAdjustments?.get(potentialEntry1) ||
    currentTeamAdjustments?.get(potentialEntry2) ||
    0;

  const updateMatchStats = (value: number) => {
    if (currentTeamAdjustments?.has(potentialEntry1)) {
      currentTeamAdjustments.set(potentialEntry1, value);
    } else {
      currentTeamAdjustments.set(potentialEntry2, value);
    }
    services.matchStatsService.updateMatchStats(matchStats);
  };

  return (
    <>
      {matchStats && batsman1Id && batsman2Id && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: primaryColour,
              fontSize: "x-small",
              borderRadius: "2px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
              color: textColour,
            }}
          >
            <NumberSelector
              label="Partnership Push"
              min={PARTNERSHIP_ADJUSTMENT_MIN}
              max={PARTNERSHIP_ADJUSTMENT_MAX}
              step={PARTNERSHIP_ADJUSTMENT_STEP}
              decimalPlaces={PARTNERSHIP_ADJUSTMENT_DP}
              initial={currentPartnershipAdjustment}
              onValid={updateMatchStats}
              onManualEdit={() => onSpinnerEdit(true)}
              onManualEditFinished={() => onSpinnerEdit(false)}
              componentsStyle={{
                backgroundColor: secondaryColour,
                border: "1px solid " + secondaryColour,
              }}
              textFieldStyle={{
                backgroundColor: primaryColour,
                color: textColour,
                borderWidth: "0px",
                height: "20px",
              }}
              buttonStyle={{
                maxHeight: "9.5px",
                minHeight: "9.5px",
                color: textColour,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
