import React from "react";

import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import {
  InningsSimulationQuestionStatistic,
  inningsSimulationQuestionStatisticNames,
  inningsSimulationQuestionStatisticWithoutNumbers,
} from "../../types/enums/question-statistic";
import { operatorNames } from "../../types/enums/questions-operator";
import {
  InningsSimulationOddsQuestion,
  SimulationOddsQuestion,
  SimulationQuestionType,
  questionTypesEnumToString,
  questionTypesStringToEnum,
  simulationQuestionTypeNames,
} from "../../types/simulator/questions/simulation-odds-question";
import { createDefaultQuestionOfType } from "../../types/simulator/questions/stored-questions";
import { UUID } from "../../types/uuid";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { format } from "../simulator-page/simulator-utils";

import { TrafficLight } from "../../types/enums/traffic-light";
import InningsSimulatorOddsQuestionEditor from "./simulator-innings-odds-question-editor";
import { StoredQuestionOddsDisplay } from "./stored-question-odds-display";

interface InningsProps {
  storedQuestionId: UUID;
  question: InningsSimulationOddsQuestion;
  editing: boolean;
  squad1: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  gameState: GameState;
  standalone?: boolean;
  odds: number;
  edge: number;
  confidence?: TrafficLight;
  onUpdate: (question: SimulationOddsQuestion) => void;
  setEditing: (editing: boolean) => void;
  onDelete: (storedQuestionId: UUID) => void;
}

export function InningsStoredQuestionDisplay({
  storedQuestionId,
  question,
  editing,
  squad1,
  team1,
  team2,
  matchFormat,
  gameState,
  standalone = true,
  odds,
  edge,
  confidence = null,
  onUpdate,
  setEditing,
  onDelete,
}: InningsProps): React.JSX.Element {
  const getTeamName = (teamId: string, team1: Team, team2: Team) => {
    return teamId === team1.teamId.value ? team1.name : team2.name;
  };
  const teamLabel = question.teamId
    ? getTeamName(question.teamId, team1, team2)
    : "Innings " + question.innings;

  return (
    <div
      className="contained column"
      style={{
        padding: "0px",
        gap: "10px",
      }}
    >
      {standalone && (
        <div
          className="header stored-question-header"
          style={{
            backgroundColor: "rgba(100, 300, 200, 0.5)",
          }}
        >
          <div>Innings Question</div>
          <div className="stored-question-buttons">
            <TooltipIconButton
              title={editing ? "Save" : "Edit"}
              icon={editing ? "save_circle" : "edit_circle"}
              onClick={() => setEditing(!editing)}
            />
            <TooltipIconButton
              title={"Delete"}
              icon={"delete_circle"}
              onClick={() => onDelete(storedQuestionId)}
            />
          </div>
        </div>
      )}
      <div
        className={
          editing
            ? "editing-stored-question-display"
            : "non-editing-stored-question-display"
        }
      >
        {!editing && (
          <>
            {teamLabel}{" "}
            {
              inningsSimulationQuestionStatisticNames[
                question.questionStatistic
              ]
            }{" "}
            {question.questionStatistic ===
              InningsSimulationQuestionStatistic.RUNS_AT_WICKET &&
              question.wicketsFallen + " "}
            {(question.questionStatistic ===
              InningsSimulationQuestionStatistic.WICKETS_AT_OVER ||
              question.questionStatistic ===
                InningsSimulationQuestionStatistic.SCORE_AT_OVER) &&
              question.over + " "}
            {!inningsSimulationQuestionStatisticWithoutNumbers.includes(
              question.questionStatistic
            ) && (
              <>
                {operatorNames[question.operator]} {format(question.operand)}
              </>
            )}
          </>
        )}
        {editing && (
          <>
            <EnumSelector
              classes="dialog-content"
              enumObject={SimulationQuestionType}
              label="Type"
              value={questionTypesStringToEnum[question.subType]}
              readableValues={simulationQuestionTypeNames}
              onSelect={(value) => {
                if (question.subType !== questionTypesEnumToString[value]) {
                  onUpdate(createDefaultQuestionOfType(value, squad1));
                }
              }}
              disabled={false}
              canClear={false}
              unavailable={[SimulationQuestionType.MULTI]}
            />
            <InningsSimulatorOddsQuestionEditor
              matchFormat={matchFormat}
              gameState={gameState}
              team1={team1}
              team2={team2}
              question={question}
              onChange={onUpdate}
            />
            {!standalone && (
              <TooltipIconButton
                title={"Delete"}
                icon={"delete_circle"}
                onClick={() => onDelete(storedQuestionId)}
              />
            )}
          </>
        )}
      </div>
      {standalone && (
        <StoredQuestionOddsDisplay
          odds={odds}
          edge={edge}
          confidence={confidence}
        />
      )}
    </div>
  );
}
