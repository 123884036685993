import React, { ReactNode, useState } from "react";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { TrafficLight } from "../../types/enums/traffic-light";
import { BetBuilderRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import {
  InningsSimulationOddsQuestion,
  MultiSimulationOddsQuestion,
  PlayerSimulationOddsQuestion,
  SimulationQuestionType,
  questionTypesStringToEnum,
} from "../../types/simulator/questions/simulation-odds-question";
import { StoredQuestion } from "../../types/simulator/questions/stored-questions";
import { InningsStoredQuestionDisplay } from "./innings-stored-question-display";
import { MultiStoredQuestionDisplay } from "./multi-stored-question-display";
import PlayerStoredQuestionDisplay from "./player-stored-question-display";

interface Props {
  storedQuestion: StoredQuestion;
  team1: Team;
  team2: Team;
  squad1: Squad;
  squad2: Squad;
  matchFormat: MatchFormat;
  gameState: GameState;
  odds: number;
  edge: number;
  simulatingUser: string;
  confidence: TrafficLight;
}

export default function StoredQuestionDisplay({
  storedQuestion,
  team1,
  team2,
  squad1,
  squad2,
  matchFormat,
  gameState,
  odds,
  edge,
  simulatingUser,
  confidence,
}: Props): React.JSX.Element {
  const [editing, setEditing] = useState<boolean>(false);

  let child: ReactNode;
  switch (questionTypesStringToEnum[storedQuestion.question.subType]) {
    case SimulationQuestionType.MULTI:
      child = (
        <MultiStoredQuestionDisplay
          storedQuestionId={storedQuestion.storedQuestionId}
          question={storedQuestion.question as MultiSimulationOddsQuestion}
          editing={editing}
          squad1={squad1}
          squad2={squad2}
          team1={team1}
          team2={team2}
          matchFormat={matchFormat}
          gameState={gameState}
          onUpdate={(question) => {
            storedQuestion.question = question;
            services.questionService.updateStoredQuestion(
              storedQuestion,
              simulatingUser
            );
          }}
          setEditing={(editing) => setEditing(editing)}
          onDelete={(storedQuestionId) =>
            services.questionService.deleteStoredQuestion(storedQuestionId)
          }
          odds={odds}
          edge={edge}
          confidence={confidence}
        />
      );
      break;
    case SimulationQuestionType.PLAYER:
      child = (
        <PlayerStoredQuestionDisplay
          storedQuestionId={storedQuestion.storedQuestionId}
          question={storedQuestion.question as PlayerSimulationOddsQuestion}
          editing={editing}
          squad1={squad1}
          squad2={squad2}
          standalone={true}
          onUpdate={(question) => {
            storedQuestion.question = question;
            services.questionService.updateStoredQuestion(
              storedQuestion,
              simulatingUser
            );
          }}
          setEditing={(editing) => setEditing(editing)}
          onDelete={(storedQuestionId) =>
            services.questionService.deleteStoredQuestion(storedQuestionId)
          }
          odds={odds}
          edge={edge}
          confidence={confidence}
        />
      );
      break;
    case SimulationQuestionType.INNINGS:
      child = (
        <InningsStoredQuestionDisplay
          storedQuestionId={storedQuestion.storedQuestionId}
          question={storedQuestion.question as InningsSimulationOddsQuestion}
          editing={editing}
          squad1={squad1}
          team1={team1}
          team2={team2}
          standalone={true}
          matchFormat={matchFormat}
          gameState={gameState}
          onUpdate={(question) => {
            storedQuestion.question = question;
            services.questionService.updateStoredQuestion(
              storedQuestion,
              simulatingUser
            );
          }}
          setEditing={(editing) => setEditing(editing)}
          onDelete={(storedQuestionId) =>
            services.questionService.deleteStoredQuestion(storedQuestionId)
          }
          odds={odds}
          edge={edge}
          confidence={confidence}
        />
      );
      break;
    default:
      child = <div>Unrecognized Question Type</div>;
  }

  return (
    <BetBuilderRoute>
      <div>{child}</div>
    </BetBuilderRoute>
  );
}
