import { UUID } from "../uuid";

export class BetfairMessage {
  public betfairStreamMessageId: UUID;
  public eventId: string;
  public createdAt: number;
  public payload: string;

  public static deserialize(msg: any): BetfairMessage {
    return {
      betfairStreamMessageId:
        msg.betfairStreamMessageId &&
        UUID.fromString(msg.betfairStreamMessageId),
      eventId: msg.eventId,
      createdAt: msg.createdAt,
      payload: msg.payload,
    };
  }
}

export enum MarketType {
  MATCH_ODDS = "MATCH_ODDS",

  INNINGS_1_RUNS_LINE = "INNINGS_1_RUNS_LINE",
  INNINGS_2_RUNS_LINE = "INNINGS_2_RUNS_LINE",

  INNINGS_1_20_OVER_LINE = "INNINGS_1_20_OVER_LINE",
  INNINGS_2_20_OVER_LINE = "INNINGS_2_20_OVER_LINE",

  INNINGS_1_6_OVER_LINE = "INNINGS_1_6_OVER_LINE",
  INNINGS_2_6_OVER_LINE = "INNINGS_2_6_OVER_LINE",

  INNINGS_1_100_BALL_LINE = "INNINGS_1_100_BALL_LINE",
  INNINGS_2_100_BALL_LINE = "INNINGS_2_100_BALL_LINE",

  INNINGS_1_25_BALL_LINE = "INNINGS_1_25_BALL_LINE",
  INNINGS_2_25_BALL_LINE = "INNINGS_2_25_BALL_LINE",

  INNINGS_1_50_OVER_LINE = "INNINGS_1_50_OVER_LINE",
  INNINGS_2_50_OVER_LINE = "INNINGS_2_50_OVER_LINE",

  INNINGS_1_10_OVER_LINE = "INNINGS_1_10_OVER_LINE",
  INNINGS_2_10_OVER_LINE = "INNINGS_2_10_OVER_LINE",
}

export const marketTypeReadableNames: Record<MarketType, string> = {
  MATCH_ODDS: "Match Odds",

  INNINGS_1_RUNS_LINE: "1st Innings Runs Line",
  INNINGS_2_RUNS_LINE: "2nd Innings Runs Line",

  INNINGS_1_20_OVER_LINE: "1st Innings 20 Overs Line",
  INNINGS_2_20_OVER_LINE: "2nd Innings 20 Overs Line",

  INNINGS_1_6_OVER_LINE: "1st Innings 6 Overs Line",
  INNINGS_2_6_OVER_LINE: "2nd Innings 6 Overs Line",

  INNINGS_1_100_BALL_LINE: "Hundred 1st Innings 100 Balls",
  INNINGS_2_100_BALL_LINE: "Hundred 2nd Innings 100 Balls",

  INNINGS_1_25_BALL_LINE: "Hundred 1st Innings 25 Balls",
  INNINGS_2_25_BALL_LINE: "Hundred 2nd Innings 25 Balls",

  INNINGS_1_50_OVER_LINE: "1st Innings 50 Overs Line",
  INNINGS_2_50_OVER_LINE: "2nd Innings 50 Overs Line",

  INNINGS_1_10_OVER_LINE: "1st Innings 10 Overs Line",
  INNINGS_2_10_OVER_LINE: "2nd Innings 10 Overs Line",
};

export class BetfairPrice {
  backPrice: number;
  layPrice: number;
  backSize: number;
  laySize: number;
  midPoint: number;
}

export class MarketSubscription {
  latestPrices: Map<MarketType, Map<string, BetfairPrice>>;
}

export class BetfairPriceUpdateMessage {
  public eventId: string;
  public subscription: MarketSubscription;

  public static deserialize(msg: any): BetfairPriceUpdateMessage {
    return {
      eventId: msg.eventId,
      subscription: BetfairPriceUpdateMessage.deserializeMarketSubscription(
        msg.subscription
      ),
    };
  }

  private static deserializeMarketSubscription(msg: any): MarketSubscription {
    return {
      latestPrices: BetfairPriceUpdateMessage.deserializeLatestPrices(
        msg.latestPrices
      ),
    };
  }

  private static deserializeLatestPrices(
    msg: any
  ): Map<MarketType, Map<string, BetfairPrice>> {
    const map: Map<MarketType, Map<string, BetfairPrice>> = new Map();
    for (const [key, value] of Object.entries(msg)) {
      map.set(
        MarketType[key],
        BetfairPriceUpdateMessage.deserializePriceMap(value)
      );
    }
    return map;
  }

  private static deserializePriceMap(msg: any): Map<string, BetfairPrice> {
    const map: Map<string, BetfairPrice> = new Map();
    for (const [key, value] of Object.entries(msg)) {
      map.set(key, value as BetfairPrice);
    }
    return map;
  }
}
