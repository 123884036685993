import { ReplaySubject, Subject } from "rxjs";

import { Match } from "../types/entities/match";
import { Player } from "../types/entities/player";
import { Squad } from "../types/entities/squad";
import { MatchType } from "../types/enums/match-type";
import { services, showMessage } from "../types/services";
import { HistoricPlayerStatsWrapper } from "../types/stats/historic-form";
import {
  MatchPlayerStats,
  PlayerStats,
  PlayerStatsWrapper,
  serializeMatchPlayerStats,
} from "../types/stats/player-stats";
import { PlayerStatsLoad } from "../types/stats/stats-load";
import { UUID } from "../types/uuid";

import {
  PlayerFormExplanation,
  deserializePlayerFormExplanation,
} from "../types/stats/form-explanation";
import { EntityService } from "./entity-services/entity-service";
import { KeycloakUser } from "./keycloak-service";

export interface HistoricStatsUpdateMessage {
  requestId: UUID;
  content: string;
  lastPlayer: string;
  done: number;
  size: number;
}

export interface HistoricPlayerFormUpdateMessage {
  requestId: UUID;
  lastPlayer: string;
  done: number;
  size: number;
}

export interface StrikeRateAndWicketPercentData {
  ball: number;
  value: number;
}

export interface StrikeRateAndWicketPercentResponse {
  strikeRateData: StrikeRateAndWicketPercentData[];
  wicketPercentData: StrikeRateAndWicketPercentData[];
}

export class PlayerStatsService extends EntityService<PlayerStatsWrapper> {
  public loadedPlayerSubject: Subject<[UUID, Player, PlayerStatsLoad][]> =
    new ReplaySubject(1);
  public loadedPlayerStats: [UUID, Player, PlayerStatsLoad][];

  public playerStatsSubject: Subject<Map<string, PlayerStatsWrapper>> =
    new ReplaySubject(1);
  public playerStats: Map<string, PlayerStatsWrapper> = null;

  public comparedUserStatsSubject: Subject<Map<string, PlayerStatsWrapper>> =
    new ReplaySubject(1);
  public teamPlayerStats: Map<string, Map<string, PlayerStatsWrapper>> =
    new Map();

  public squad1: Squad;
  public squad2: Squad;

  private calculateAllStatsRequestId: UUID;
  private calculateAllFormRequestId: UUID;
  public historicStatsRequestSubject: Subject<HistoricStatsUpdateMessage> =
    new ReplaySubject(1);
  public historicFormRequestSubject: Subject<HistoricPlayerFormUpdateMessage> =
    new ReplaySubject(1);
  public historicForm: Map<string, HistoricPlayerStatsWrapper> = new Map();
  public historicFormSubject: Subject<Map<string, HistoricPlayerStatsWrapper>> =
    new ReplaySubject(1);

  public initiateLoadedStats() {
    services.currentGameService.currentMatchSubject.subscribe(
      (currentMatch: Match) => {
        this.updateStats(currentMatch);
      }
    );

    services.currentGameService.squadsSubject.subscribe((squads: Squad[]) => {
      if (!!squads) {
        this.squad1 = squads[0];
        this.squad2 = squads[1];
        this.resetLoadedStatsArrays();
        this.loadedPlayerSubject.next(this.loadedPlayerStats);
      } else {
        this.squad1 = null;
        this.squad2 = null;
        this.loadedPlayerStats = null;
        this.loadedPlayerSubject.next(this.loadedPlayerStats);
      }
    });

    services.keycloakService.comparedUserSubject.subscribe(
      (comparedUser: KeycloakUser) => this.broadcastComparedStats(comparedUser)
    );
  }

  public setCalculateRequestId(requestId: UUID) {
    this.calculateAllFormRequestId = requestId;
    this.calculateAllStatsRequestId = requestId;
  }

  private updateStats(match: Match) {
    if (!!match) {
      this.getAllForMatch(match.matchId).then(
        (result: Map<string, PlayerStatsWrapper>) => {
          if (!!result) {
            this.playerStats = result;
            this.playerStatsSubject.next(result);
          }
        }
      );
      this.getAllTeamStatsForMatch(match.matchId).then(
        (result: Map<string, Map<string, PlayerStatsWrapper>>) => {
          if (!!result) {
            this.teamPlayerStats = result;
            this.broadcastComparedStats(services.keycloakService.comparedUser);
          }
        }
      );
    } else {
      this.playerStats = new Map();
      this.playerStatsSubject.next(this.playerStats);
      this.teamPlayerStats = new Map();
      this.broadcastComparedStats(services.keycloakService.comparedUser);
    }
  }

  private broadcastComparedStats(comparedUser: KeycloakUser) {
    if (!!comparedUser) {
      this.comparedUserStatsSubject.next(
        this.teamPlayerStats.get(comparedUser.id) || new Map()
      );
    } else {
      this.comparedUserStatsSubject.next(new Map());
    }
  }

  public async recalculateHistoricPlayerForm(matchType: MatchType) {
    const params: Map<string, string> = new Map();
    this.calculateAllFormRequestId = UUID.randomUUID();
    params.set("requestId", this.calculateAllFormRequestId.value);
    params.set("matchType", matchType);
    this.httpService
      .get(
        `/api/historic-stats-controller/recalculate-historic-player-form`,
        params
      )
      .catch((reason) => {
        showMessage(
          `Failed to generate historic player form: ${reason}`,
          "error"
        );

        return;
      });
  }

  public async getDefaultStats(
    matchType: MatchType
  ): Promise<Map<number, PlayerStatsWrapper>> {
    const params: Map<string, string> = new Map();
    params.set("matchType", matchType);
    return await this.httpService
      .get(`/api/${this.controllerName}/get-default-stats`, params)
      .then((response: any) => {
        const result: Map<number, PlayerStatsWrapper> = new Map();
        Object.keys(response).forEach((key) => {
          result.set(
            Number(key),
            PlayerStatsWrapper.deserializeOne(response[key])
          );
        });

        return result;
      })
      .catch((reason) => {
        showMessage(`Failed to get all player stats: ${reason}`, "error");

        return null;
      });
  }

  public async getPlayerDefaultStats(
    matchId: UUID,
    playerId: UUID
  ): Promise<PlayerStats> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("playerId", playerId.value);
    return await this.httpService
      .get(
        `/api/${this.controllerName}/restore-from-player-default-stats`,
        params
      )
      .then((response: any) => {
        return PlayerStatsWrapper.deserializePlayerStats(response);
      })
      .catch((reason) => {
        showMessage(`Failed to get player default stats: ${reason}`, "error");

        return null;
      });
  }

  public async savePlayerDefaultStats(
    matchType: MatchType,
    playerId: UUID,
    playerStats: PlayerStats
  ): Promise<boolean> {
    const request = {
      matchType,
      playerId: playerId.value,
      stats: playerStats,
    };
    return await this.httpService
      .post(`/api/${this.controllerName}/update-player-default-stats`, request)
      .then(() => {
        showMessage(`Updated default player stats`, "info");

        return true;
      })
      .catch((reason) => {
        showMessage(
          `Failed to update player default stats: ${reason}`,
          "error"
        );

        return null;
      });
  }

  public async saveDefaultStats(stats: PlayerStatsWrapper[]): Promise<Boolean> {
    return this.httpService
      .post(
        `/api/${this.controllerName}/save-default-stats`,
        stats.map((s) => PlayerStatsWrapper.serialize(s)),
        false
      )
      .then(() => true)
      .catch((reason) => {
        showMessage(`Failed to update all player stats: ${reason}`, "error");

        return false;
      });
  }

  public async recalculateHistoricPlayerFormForIds(players: Player[]) {
    const params: Map<string, string> = new Map();
    this.calculateAllFormRequestId = UUID.randomUUID();
    const playerIds: string[] = players.map((p) => p.playerId.value);
    params.set("playerIds", playerIds.join(","));
    params.set("requestId", this.calculateAllFormRequestId.value);
    this.httpService
      .get(
        `/api/historic-stats-controller/recalculate-historic-player-form-for-ids`,
        params
      )
      .catch((reason) => {
        showMessage(
          `Failed to generate historic player form: ${reason}`,
          "error"
        );

        return;
      });
  }

  public async getHistoricPlayerForm(playerId: UUID, matchType: MatchType) {
    const params: Map<string, string> = new Map();
    params.set("playerId", playerId.value);
    params.set("matchType", matchType);
    this.httpService
      .get(`/api/historic-stats-controller/historic-player-form`, params)
      .then((result: any) => {
        const historicPlayerStats: HistoricPlayerStatsWrapper =
          HistoricPlayerStatsWrapper.deserializeOne(result);
        if (historicPlayerStats.historicPlayerStatsId !== null) {
          this.historicForm.set(playerId.value, historicPlayerStats);
          this.historicFormSubject.next(this.historicForm);
        } else {
          showMessage(`No historic stats available for player`, "error");
        }
      })
      .catch((reason) => {
        showMessage(`Failed to get historic player form: ${reason}`, "error");

        return;
      });
  }

  public async generateAllHistoricPlayerStats() {
    const params: Map<string, string> = new Map();
    this.calculateAllStatsRequestId = UUID.randomUUID();
    params.set("requestId", this.calculateAllStatsRequestId.value);
    this.httpService
      .get(`/api/historic-stats-controller/historic-player-stats`, params)
      .catch((reason) => {
        showMessage(`Failed to generate all player stats: ${reason}`, "error");

        return;
      });
  }

  public async generateAllPlayerStats(matchId: UUID): Promise<void> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return await this.httpService
      .get(`/api/${this.controllerName}/generate-all-player-stats`, params)
      .catch((reason) => {
        showMessage(`Failed to generate all player stats: ${reason}`, "error");

        return;
      });
  }

  public async defaultAllPlayerStats(matchId: UUID): Promise<void> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return await this.httpService
      .get(`/api/${this.controllerName}/default-all-player-stats`, params)
      .catch((reason) => {
        showMessage(`Failed to default all player stats: ${reason}`, "error");

        return;
      });
  }

  public async generatePlayerStats(
    matchId: UUID,
    playerId: UUID
  ): Promise<PlayerStats> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("playerId", playerId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/generate-player-stats`, params)
      .then((response: any) => {
        return PlayerStatsWrapper.deserializePlayerStats(response);
      });
  }

  public async explainPlayerStats(
    matchId: UUID,
    playerId: UUID
  ): Promise<PlayerFormExplanation> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("playerId", playerId.value);

    return await this.httpService
      .get(
        `/api/${this.controllerName}/generate-player-stats-explanation`,
        params
      )
      .then((response: any) => {
        return deserializePlayerFormExplanation(response);
      });
  }

  public async getDefaultPlayerStats(
    matchId: UUID,
    playerId: UUID
  ): Promise<PlayerStats> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("playerId", playerId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/get-default-player-stats`, params)
      .then((response: any) => {
        return PlayerStatsWrapper.deserializePlayerStats(response);
      });
  }

  private async getAllForMatch(
    matchId: UUID
  ): Promise<Map<string, PlayerStatsWrapper>> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/all-player-stats`, params)
      .then((response: any) => {
        if (response === true) {
          return null;
        }

        return PlayerStatsWrapper.deserializeMap(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load all player stats: ${reason}`, "error");

        return null;
      });
  }

  private async getAllTeamStatsForMatch(
    matchId: UUID
  ): Promise<Map<string, Map<string, PlayerStatsWrapper>>> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/team-all-player-stats`, params)
      .then((response: any) => {
        if (response === true) {
          return null;
        }

        const map: Map<string, Map<string, PlayerStatsWrapper>> = new Map();
        Object.keys(response).forEach((userId) => {
          map.set(userId, PlayerStatsWrapper.deserializeMap(response[userId]));
        });

        return map;
      })
      .catch((reason) => {
        showMessage(`Failed to load all team player stats: ${reason}`, "error");

        return null;
      });
  }

  public async getAllForPlayer(
    playerId: UUID,
    matchId: UUID
  ): Promise<PlayerStatsWrapper[]> {
    const params: Map<string, string> = new Map();
    params.set("playerId", playerId.value);
    params.set("matchId", matchId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/recent-player-stats`, params)
      .then((response: any) => {
        return PlayerStatsWrapper.deserializeList(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load recent player stats: ${reason}`, "error");

        return null;
      });
  }

  public async updatePlayerStats(
    playerStats: PlayerStatsWrapper
  ): Promise<void> {
    return await this.httpService
      .post(
        `/api/${this.controllerName}/update-player-stats`,
        PlayerStatsWrapper.serialize(playerStats)
      )
      .then(() => showMessage("Player stats saved", "success"))
      .catch((reason) => {
        showMessage(`Failed to save player stats: ${reason}`, "error");

        return;
      });
  }

  public updateMultiplePlayerStats(
    playerStats: Map<string, PlayerStatsWrapper>,
    matchId: UUID
  ) {
    const requestBody = {
      matchId: matchId.value,
      playerStatsMap: PlayerStatsWrapper.serializePlayerStatsMap(playerStats),
    };

    this.httpService
      .post(
        `/api/${this.controllerName}/update-multiple-player-stats`,
        requestBody
      )
      .then(() => showMessage("Player stats saved", "success"))
      .catch((reason) => {
        showMessage(`Failed to save player stats: ${reason}`, "error");
      });
  }

  public async usePlayerStats(
    playerId: UUID,
    playerStats: PlayerStatsWrapper
  ): Promise<boolean> {
    const matchPlayerStats: MatchPlayerStats = {
      playerId: playerId,
      matchId: services.currentGameService.currentMatchId,
      playerStatsId: playerStats.playerStatsId,
      matchPlayerStatsId: null,
      createdBy: UUID.fromString(services.keycloakService.getUserId()),
    };

    return await this.httpService
      .post(
        `/api/${this.controllerName}/use-player-stats`,
        serializeMatchPlayerStats(matchPlayerStats)
      )
      .then((response: any) => {
        return response as boolean;
      })
      .catch((reason) => {
        showMessage(`Failed to use player stats: ${reason}`, "error");

        return false;
      });
  }

  public webSocketStatsLoaded(message: any) {
    this.playerStatsLoaded({
      playerId: !!message.entityId ? UUID.fromString(message.entityId) : null,
      result: message.result,
      description: message.description,
    });
  }

  public webSocketOneStatsUpdated(message: any) {
    const newPlayerStatsWrapper: PlayerStatsWrapper = !!message.stats
      ? PlayerStatsWrapper.deserializeOne(message.stats)
      : null;
    const newPlayerStatsMap: Map<string, PlayerStatsWrapper> = new Map();

    if (message.causingUser === services.keycloakService.getUserId()) {
      this.playerStats.forEach(
        (currentPlayerStatsWrapper: PlayerStatsWrapper, key: string) => {
          if (
            currentPlayerStatsWrapper.playerStatsId.value ===
            newPlayerStatsWrapper.playerStatsId.value
          ) {
            newPlayerStatsMap.set(key, newPlayerStatsWrapper);
          } else {
            newPlayerStatsMap.set(key, currentPlayerStatsWrapper);
          }
        }
      );

      this.playerStats = newPlayerStatsMap;
      this.playerStatsSubject.next(newPlayerStatsMap);
    } else {
      let found: boolean = false;
      !!this.teamPlayerStats.get(message.causingUser) &&
        this.teamPlayerStats
          .get(message.causingUser)
          .forEach(
            (currentPlayerStatsWrapper: PlayerStatsWrapper, key: string) => {
              if (
                currentPlayerStatsWrapper.playerStatsId.value ===
                newPlayerStatsWrapper.playerStatsId.value
              ) {
                newPlayerStatsMap.set(key, newPlayerStatsWrapper);
                found = true;
              } else {
                newPlayerStatsMap.set(key, currentPlayerStatsWrapper);
              }
            }
          );
      this.teamPlayerStats.set(message.causingUser, newPlayerStatsMap);
      this.broadcastComparedStats(services.keycloakService.comparedUser);
      if (found) {
        showMessage(`${message.name} updated one player stats`);
      }
    }
  }

  public webSocketAllStatsUpdated(message: any) {
    if (
      !!message.matchId &&
      !!services.currentGameService.currentMatch &&
      !!services.currentGameService.currentMatch.matchId &&
      services.currentGameService.currentMatch.matchId.value === message.matchId
    ) {
      const newPlayerStatsMap: Map<string, PlayerStatsWrapper> = !!message.stats
        ? PlayerStatsWrapper.deserializeMap(message.stats)
        : new Map();

      if (message.causingUser === services.keycloakService.getUserId()) {
        newPlayerStatsMap.forEach(
          (currentPlayerStatsWrapper: PlayerStatsWrapper, playerId: string) => {
            this.playerStats.set(playerId, currentPlayerStatsWrapper);
          }
        );
        this.playerStatsSubject.next(this.playerStats);
      } else {
        this.teamPlayerStats.set(message.causingUser, newPlayerStatsMap);
        this.broadcastComparedStats(services.keycloakService.comparedUser);
        showMessage(`${message.name} updated all player stats`);
      }
    }
  }

  public async getStrikeRateAndWicketPercentForAggression(
    playerStats: PlayerStats,
    aggression: number
  ): Promise<StrikeRateAndWicketPercentResponse> {
    const body = {
      playerStats: PlayerStatsWrapper.serializePlayerStats(playerStats),
      aggression: aggression,
    };

    return this.httpService
      .post(
        `/api/${this.controllerName}/get-default-player-strike-rate-and-wicket-percent-summary-at-aggression`,
        body
      )
      .then((response) => {
        const strikeRateData: StrikeRateAndWicketPercentData[] = [];
        const wicketPercentData: StrikeRateAndWicketPercentData[] = [];

        response.forEach((item, index) => {
          strikeRateData.push({ ball: index, value: item[0] });
          wicketPercentData.push({ ball: index, value: item[1] });
        });

        return { strikeRateData, wicketPercentData };
      })
      .catch((reason) => {
        showMessage(
          `Failed to get strike rate and wicket percent for aggression: ${reason}`,
          "error"
        );
        throw reason;
      });
  }

  private playerStatsLoaded(playerStatsLoad: PlayerStatsLoad) {
    if (!!this.loadedPlayerStats) {
      const playerStats = this.loadedPlayerStats.find(
        ([id]) => playerStatsLoad.playerId.value === id.value
      );
      const index = this.loadedPlayerStats.indexOf(playerStats);
      if (!!this.loadedPlayerStats[index]) {
        this.loadedPlayerStats[index][2] = playerStatsLoad;
      }
      this.loadedPlayerSubject.next(this.loadedPlayerStats);
    }
  }

  private resetLoadedStatsArrays() {
    if (!!this.squad1 && !!this.squad2) {
      const playerStatsLoads: [UUID, Player, PlayerStatsLoad][] = [];
      this.squad1.players.forEach((player: Player) => {
        playerStatsLoads.push([player.playerId, player, null]);
      });
      this.squad2.players.forEach((player: Player) => {
        playerStatsLoads.push([player.playerId, player, null]);
      });
      this.loadedPlayerStats = playerStatsLoads;
    }
  }

  public historicPlayerStatsUpdate(message: any) {
    if (
      !!message.requestId &&
      !!this.calculateAllStatsRequestId &&
      this.calculateAllStatsRequestId.value === message.requestId
    ) {
      this.historicStatsRequestSubject.next(
        this.deserializeHistoricStatsUpdateMessage(message)
      );
    }
  }

  public historicPlayerFormUpdate(message: any) {
    if (
      !!message.requestId &&
      !!this.calculateAllFormRequestId &&
      this.calculateAllFormRequestId.value === message.requestId
    ) {
      this.historicFormRequestSubject.next(
        this.deserializeHistoricFormUpdateMessage(message)
      );
    }
  }

  private deserializeHistoricStatsUpdateMessage(
    message: any
  ): HistoricStatsUpdateMessage {
    return {
      requestId: UUID.fromString(message.requestId),
      content: message.content,
      lastPlayer: message.lastPlayer,
      done: message.done,
      size: message.size,
    };
  }

  private deserializeHistoricFormUpdateMessage(
    message: any
  ): HistoricPlayerFormUpdateMessage {
    return {
      requestId: UUID.fromString(message.requestId),
      lastPlayer: message.lastPlayer,
      done: message.done,
      size: message.size,
    };
  }
}
