export enum TrafficLight {
  RED = "RED",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
}

export const trafficLightColours: Record<TrafficLight, string> = {
  RED: "red",
  YELLOW: "yellow",
  GREEN: "green",
};
