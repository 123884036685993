import { MenuItem, Select } from "@mui/material";
import {
  ActiveElement,
  CartesianScaleTypeRegistry,
  Chart,
  PluginOptionsByType,
  Point,
  ScaleOptionsByType,
} from "chart.js";
import { ChartEvent } from "chart.js/dist/core/core.plugins";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import ChartJSDragDataPlugin from "chartjs-plugin-dragdata";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import {
  MatchStatsWrapper,
  rangeProperties,
  readableMatchStatsByBallNames,
} from "../../types/stats/match-stats";
import { round } from "../../types/util-functions";
import { copyMap, sortMap } from "../component-utils";
import { batchUpdate } from "../match-stats-page/match-stats-utils";
import { formatToDp } from "../simulator-page/simulator-utils";
import { areStatsEqual, getToolTipMessage } from "./stats-editing-utils";
import StealStatsButton from "./steal-stats-button";
import StealStatsHeadingAndButton from "./steal-stats-heading-and-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  matchFormat: MatchFormat;
  gameState: GameState;
  onUpdate: (updatedStats: MatchStatsWrapper) => void;
}

export default function MatchStatsEditorChart({
  currentStats,
  comparedStats,
  comparedUserName,
  matchFormat,
  gameState,
  onUpdate,
}: Readonly<Props>) {
  const STATS_TO_STEAL: string[] = [
    "strikeRateBiasByBall",
    "wicketBiasByBall",
    "pushByBall",
    "paceStrikeRateBiasByBall",
    "paceWicketBiasByBall",
    "pacePushByBall",
    "spinStrikeRateBiasByBall",
    "spinWicketBiasByBall",
    "spinPushByBall",
  ];

  const [currentGraph, setCurrentGraph] = useState<string>(
    "strikeRateBiasByBall"
  );
  const [dragStartX, setDragStartX] = useState<number>(null);
  const [dragStartY, setDragStartY] = useState<number>(null);

  const data: [number, number][] = [];
  const comparedData: [number, number][] = [];

  let totalBallsBowled = 0;
  let totalBallsInFormat = 0;

  if (gameState && matchFormat) {
    for (let i = 1; i < gameState.innings; i++) {
      totalBallsBowled =
        totalBallsBowled + MatchFormat.getTotalBallsInInnings(i, matchFormat);
    }
    totalBallsBowled = totalBallsBowled + gameState.ballNumber;
    totalBallsInFormat = MatchFormat.getTotalBalls(matchFormat);

    if (currentStats) {
      currentStats
        .getStats()
        [currentGraph].forEach((value, key) => data.push([key, value]));
    }

    if (comparedStats) {
      comparedStats
        .getStats()
        [currentGraph].forEach((value, key) => comparedData.push([key, value]));
    }
  }

  function updateValue(originalXIndex: number, newX: number, newY: number) {
    const updatedDataPoints: Map<number, number> = copyMap(
      currentStats.matchStats[currentGraph]
    );
    const firstIndex = originalXIndex === 0;
    const lastIndex = originalXIndex === data.length - 1;

    if (firstIndex) {
      // do not allow dragging first point away from x = 0
      updatedDataPoints.set(0, newY);
    } else if (lastIndex) {
      // do not allow dragging final point away from x = total balls in format
      updatedDataPoints.set(totalBallsInFormat, newY);
    } else {
      // remove the original point and insert new one
      updatedDataPoints.delete(
        Array.from(updatedDataPoints.keys())[originalXIndex]
      );
      updatedDataPoints.set(newX, newY);
    }

    currentStats.matchStats[currentGraph] = sortMap(updatedDataPoints);
    onUpdate(currentStats);
  }

  function addPoint(x: number, y: number) {
    const updatedDataPoints: Map<number, number> = copyMap(
      currentStats.matchStats[currentGraph]
    );
    updatedDataPoints.set(x, y);
    const sorted = sortMap(updatedDataPoints);
    currentStats.matchStats[currentGraph] = sorted;
    onUpdate(currentStats);
  }

  function removePoint(x: number) {
    const updatedDataPoints: Map<number, number> = copyMap(
      currentStats.matchStats[currentGraph]
    );
    updatedDataPoints.delete(x);
    currentStats.matchStats[currentGraph] = updatedDataPoints;
    onUpdate(currentStats);
  }

  function onSteal() {
    batchUpdate(
      currentStats,
      comparedStats,
      STATS_TO_STEAL,
      onUpdate.bind(this)
    );
  }

  function onGraphClick(
    event: ChartEvent,
    elements: ActiveElement[],
    chart: Chart
  ) {
    const {
      scales: { x, y },
    } = chart;
    const hoveringOnPoint = elements.length > 0;

    if (hoveringOnPoint) {
      const xValueToRemove =
        chartData.datasets[elements[0].datasetIndex].data[elements[0].index].x;
      // do not want to delete first or last point
      if (xValueToRemove !== 0 && xValueToRemove !== totalBallsInFormat) {
        removePoint(xValueToRemove);
      }
    } else {
      const newXValue = Number(round(x.getValueForPixel(event.x)));
      const newYValue = Number(round(y.getValueForPixel(event.y), 3));
      addPoint(newXValue, newYValue);
    }
  }

  function onGraphDragStart(e: MouseEvent) {
    setDragStartX(e.clientX);
    setDragStartY(e.clientY);
  }

  function onGraphDragEnd(e: MouseEvent, datasetIndex, index, value) {
    const dataset = chartData.datasets[datasetIndex];
    const previousX = dataset.data[index - 1]?.x;
    const thisXIndex = index;
    const nextX = dataset.data[index + 1]?.x;
    const maxX = nextX - 1 || totalBallsInFormat;
    const minX = previousX + 1 || 0;
    const moveX = e.clientX - dragStartX;
    const moveY = e.clientY - dragStartY;
    if (moveX !== 0 || moveY !== 0) {
      updateValue(
        thisXIndex,
        Math.max(minX, Math.min(maxX, Number(round((value as Point).x)))),
        Number(round((value as Point).y, 3))
      );
    }
  }

  function buildChartData(
    data: [number, number][],
    fillColour: string,
    lineColour: string
  ) {
    return {
      type: "line",
      datasets: [
        {
          dataLabels: { display: false },
          data: data.map((d) => {
            return { x: d[0], y: formatToDp(d[1], 4) };
          }),
          backgroundColor: fillColour,
          fill: true,
          borderColor: lineColour,
          borderWidth: 2,
        },
      ],
    };
  }

  const chartData = buildChartData(
    data,
    "rgba(64, 101, 197, 0.3)",
    "rgba(64, 101, 197, 1)"
  );

  const comparedChartData = buildChartData(
    comparedData,
    "rgba(255, 255, 255, 0.3)",
    "rgba(255, 255, 255, 1)"
  );

  const verticalLinePlugin = {
    id: "verticalArbitraryLine",
    afterDraw: (chart) => {
      const {
        ctx,
        chartArea: { top, height },
        scales: { x },
      } = chart;
      const pluginOptions =
        chart.config._config.options.plugins.verticalArbitraryLine;
      ctx.save();
      ctx.fillStyle = pluginOptions.colour;
      const xCoord = x.getPixelForValue(pluginOptions.totalBalls);
      ctx.fillRect(xCoord, top, 1, height);
      ctx.restore();
    },
  };

  const plugins = [ChartJSDragDataPlugin, verticalLinePlugin];

  // this user's chart should allow dragging/adding/removing points
  // should have red line showing ball number
  const pluginOptions = {
    legend: {
      display: false,
    },
    verticalArbitraryLine: {
      totalBalls: totalBallsBowled,
      colour: "red",
    },
    dragData: {
      dragData: true,
      dragX: true,
      dragY: true,
      onDragStart: (event: MouseEvent, _datasetIndex, _index, _value) =>
        onGraphDragStart(event),
      onDragEnd: onGraphDragEnd,
    },
  } as _DeepPartialObject<PluginOptionsByType<"line">>;

  // compared chart should not allow dragging/adding/removing points
  // should have white line showing ball number
  const comparedPluginOptions = {
    legend: {
      display: false,
    },
    verticalArbitraryLine: {
      totalBalls: totalBallsBowled,
      colour: "white",
    },
    dragData: {
      dragData: false,
      dragX: false,
      dragY: false,
    },
  } as _DeepPartialObject<PluginOptionsByType<"line">>;

  // y min/max dictated by current graph (depending on bias or push adjustment)
  // x min/max dictated by number of balls in format
  const scaleOptions = {
    x: {
      type: "linear",
      title: {
        text: "Ball Number",
        display: true,
      },
      min: 0,
      max: totalBallsInFormat,
    },
    y: {
      min: rangeProperties[currentGraph][0],
      max: rangeProperties[currentGraph][1],
    },
  } as _DeepPartialObject<{
    [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry>;
  }>;

  return (
    <div className="match-stats-column-section">
      <div className="stats-modal-section-head">Match Conditions by Ball</div>

      <div className="ground-conditions-content">
        <div className="ground-conditions-chart-selector">
          <Select
            value={currentGraph}
            onChange={(selection) => setCurrentGraph(selection.target.value)}
            variant="standard"
          >
            {Object.keys(readableMatchStatsByBallNames).map((key) => (
              <MenuItem key={`ground-stat-type-${key}`} value={key}>
                {readableMatchStatsByBallNames[key]}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div
          className="ground-conditions-chart-wrapper"
          style={{ height: "200px" }}
        >
          {matchFormat && gameState && (
            <Line
              data={chartData}
              plugins={plugins}
              options={{
                animation: false,
                responsive: true,
                maintainAspectRatio: false,
                plugins: pluginOptions,
                scales: scaleOptions,
                onClick: onGraphClick,
              }}
            />
          )}
        </div>

        {comparedStats && (
          <div className="comparison">
            <StealStatsHeadingAndButton comparedUserName={comparedUserName}>
              <StealStatsButton
                comparedUserName={comparedUserName}
                disabled={areStatsEqual(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  "matchStats"
                )}
                tooltipMessage={getToolTipMessage(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  comparedUserName,
                  "matchStats"
                )}
                onClickHandler={onSteal}
              />
            </StealStatsHeadingAndButton>
            <div
              className="ground-conditions-chart-wrapper"
              style={{ height: "180px" }}
            >
              <Line
                data={comparedChartData}
                plugins={plugins}
                options={{
                  animation: false,
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: scaleOptions,
                  plugins: comparedPluginOptions,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
