import { Switch } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import {
  AdminPreferences,
  AdminPreferencesField,
  LeagueStrengthSettings,
  MatchSpecificSettings,
  ToggleType,
  cloneMatchSpecificSettings,
  humanReadableToggleTypes,
  isToggleEnabled,
} from "../../types/preferences/admin-preferences";
import { EmployeeRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

import LeagueStrengthSettingsComponent from "./league-strength-settings-component";
import { MatchSpecificSettingsComponent } from "./match-specific-settings-component";

interface State {
  adminPreferences: AdminPreferences;
  expandedRow: number;
  powerplayPercentsExpanded: boolean;
}

interface BowlingTypeByPhaseMultiplierConfig {
  property: "spinMultipliersByPhase" | "paceMultipliersByPhase";
  title: string;
}

export const bowlingTypeByPhaseMultipliersConfig: BowlingTypeByPhaseMultiplierConfig[] =
  [
    {
      property: "spinMultipliersByPhase",
      title: "Spin",
    },
    {
      property: "paceMultipliersByPhase",
      title: "Pace",
    },
  ];

export class AdminSettingsPage extends Component<{}, State> {
  private subscriptions: Subscription[] = [];
  private static readonly DEFAULT_STATE = {
    adminPreferences: null,
    expandedRow: null,
    powerplayPercentsExpanded: false,
  };

  constructor(props) {
    super(props);
    this.state = AdminSettingsPage.DEFAULT_STATE;
  }

  componentDidMount() {
    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (update: AdminPreferences) =>
          this.setState({ adminPreferences: update })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updatePreferences(field: string, value: AdminPreferencesField) {
    const toUpdate = {
      ...this.state.adminPreferences,
      [field]: value,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private updateTogglePreference(toggleType: ToggleType) {
    const updatedToggles = this.state.adminPreferences.toggles.map((toggle) => {
      if (toggle.toggleType === toggleType) {
        return {
          ...toggle,
          enabled: !toggle.enabled,
        };
      }

      return toggle;
    });

    this.updatePreferences("toggles", updatedToggles);
  }

  private createMatchSpecificSettings() {
    const currentMatchSpecificSettings =
      this.state.adminPreferences.matchSpecificSettings;
    currentMatchSpecificSettings.unshift({
      ...cloneMatchSpecificSettings(
        this.state.adminPreferences.defaultSettings
      ),
      name: "Untitled",
      createdAt: Date.now(),
      matchTypes: [],
      matchFormats: [],
      teams: [],
      series: [],
    });
    const toUpdate = {
      ...this.state.adminPreferences,
      matchSpecificSettings: currentMatchSpecificSettings,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private updateMatchSpecificSettings(
    matchSpecificSettings: MatchSpecificSettings,
    order: number
  ) {
    const currentMatchSpecificSettings =
      this.state.adminPreferences.matchSpecificSettings;
    currentMatchSpecificSettings[order] = matchSpecificSettings;
    const toUpdate = {
      ...this.state.adminPreferences,
      matchSpecificSettings: currentMatchSpecificSettings,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private updateDefaultSettings(defaultSettings: MatchSpecificSettings) {
    const toUpdate = {
      ...this.state.adminPreferences,
      defaultSettings,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private deleteMatchSpecificSettings(order: number) {
    const currentMatchSpecificSettings =
      this.state.adminPreferences.matchSpecificSettings;
    currentMatchSpecificSettings.splice(order, 1);
    const toUpdate = {
      ...this.state.adminPreferences,
      matchSpecificSettings: currentMatchSpecificSettings,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  public render() {
    return (
      <EmployeeRoute>
        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Admin Settings</div>
          </div>
          {!!this.state.adminPreferences && (
            <div className="admin-settings-content">
              <MatchSpecificSettingsComponent
                initialSettings={this.state.adminPreferences.defaultSettings}
                onUpdate={(updatedMatchSpecificSettings) =>
                  this.updateDefaultSettings(updatedMatchSpecificSettings)
                }
                isDefault={true}
              />
              {isToggleEnabled(
                this.state.adminPreferences,
                ToggleType.LEAGUE_STRENGTH
              ) && (
                <>
                  <hr />
                  <LeagueStrengthSettingsComponent
                    adminPreferences={this.state.adminPreferences}
                    onChange={(updatedSettings: LeagueStrengthSettings[]) => {
                      this.updatePreferences(
                        "leagueStrengthSettings",
                        updatedSettings
                      );
                    }}
                  />
                </>
              )}
              <hr />
              <div className="admin-settings-default-content-section-header">
                Toggles
                {this.state.adminPreferences.toggles.map((toggle) => {
                  return (
                    <div key={toggle.toggleType}>
                      <p>{humanReadableToggleTypes[toggle.toggleType]}</p>
                      <div>
                        <Switch
                          checked={toggle.enabled}
                          onChange={() =>
                            this.updateTogglePreference(toggle.toggleType)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!!this.state.adminPreferences && (
            <div className="admin-settings-content">
              <div className="admin-settings-specific-match-settings-content">
                <div className="admin-settings-match-specific-buttons">
                  <div>Match Specific Settings</div>
                  <TooltipIconButton
                    title={"Add Match Specific Setting"}
                    onClick={() => this.createMatchSpecificSettings()}
                    icon={"add"}
                  />
                </div>
                {this.state.adminPreferences.matchSpecificSettings.length ===
                  0 && (
                  <div className="italic">No Match Specific Preferences</div>
                )}
                {this.state.adminPreferences.matchSpecificSettings.map(
                  (matchSpecificSettings, order) => (
                    <div key={`match-specific-settings-row-${order}`}>
                      <MatchSpecificSettingsComponent
                        initialSettings={matchSpecificSettings}
                        onUpdate={(updatedMatchSpecificSettings) =>
                          this.updateMatchSpecificSettings(
                            updatedMatchSpecificSettings,
                            order
                          )
                        }
                        onDelete={() => this.deleteMatchSpecificSettings(order)}
                        onExpand={() =>
                          this.setState({
                            expandedRow:
                              order === this.state.expandedRow ? null : order,
                          })
                        }
                        expanded={this.state.expandedRow === order}
                        isDefault={false}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </EmployeeRoute>
    );
  }
}
