import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { HistoricGroundFormUpdateMessage } from "../../services/ground-stats-service";
import {
  HistoricPushMessage,
  PushBracketUpdateMessage,
} from "../../services/historic-push-service";
import { MultipleStageUpdateMessage } from "../../services/multiple-stage-update-service";
import { HistoricPlayerFormUpdateMessage } from "../../services/player-stats-service";
import { services } from "../../types/services";
import {
  calculateProgress,
  calculateProgressMessage,
  getLatestGroundMessage,
  getLatestPlayerMessage,
} from "../component-utils";
import { LinearProgressWithLabel } from "../historic-player-stats-page/all-player-stats-component";

export default function MultiStageUpdateProgressComponent() {
  const [currentStage, setCurrentStage] = useState<number>(null);
  const [totalStages, setTotalStages] = useState<number>(null);
  const [latestHistoricPushUpdate, setLatestHistoricPushUpdate] =
    useState<HistoricPushMessage>(null);
  const [latestPushBracketUpdate, setLatestPushBracketUpdate] =
    useState<PushBracketUpdateMessage>(null);
  const [latestHistoricGroundFormUpdate, setLatestHistoricGroundFormUpdate] =
    useState<HistoricGroundFormUpdateMessage>(null);
  const [latestHistoricPlayerFormUpdate, setLatestHistoricPlayerFormUpdate] =
    useState<HistoricPlayerFormUpdateMessage>(null);

  useEffect(() => {
    const subscriptions: Subscription[] = [];

    subscriptions.push(
      services.multipleStageUpdateService.multipleStageSubject.subscribe(
        (update: MultipleStageUpdateMessage) => {
          setCurrentStage(update.currentStage);
          setTotalStages(update.totalStages);
        }
      ),

      services.historicPushService.historicPushUpdateSubject.subscribe(
        setLatestHistoricPushUpdate
      ),

      services.historicPushService.pushBracketsUpdateSubject.subscribe(
        setLatestPushBracketUpdate
      ),

      services.groundStatsService.historicFormRequestSubject.subscribe(
        setLatestHistoricGroundFormUpdate
      ),

      services.playerStatsService.historicFormRequestSubject.subscribe(
        setLatestHistoricPlayerFormUpdate
      )
    );

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []);

  return (
    <>
      <div className="ground-display loading-bar">
        {`Recalculating historic stats... Currently on stage ${currentStage} of ${totalStages}`}
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={(currentStage / totalStages) * 100} />
        </Box>
      </div>
      {currentStage === 1 && (
        <div className="ground-display loading-bar">
          {`Recalculating Historic Push for match: ${calculateProgressMessage(
            latestHistoricPushUpdate
          )}`}
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel
              value={calculateProgress(latestHistoricPushUpdate)}
            />
          </Box>
        </div>
      )}
      {currentStage === 2 && (
        <div className="ground-display loading-bar">
          {`Recalculating Push Brackets ${calculateProgressMessage(
            latestPushBracketUpdate
          )}`}
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel
              value={calculateProgress(latestPushBracketUpdate)}
            />
          </Box>
        </div>
      )}
      {currentStage === 3 && (
        <div className="ground-display loading-bar">
          {`Recalculating Historic Ground Form: ${getLatestGroundMessage(
            latestHistoricGroundFormUpdate
          )}: ${calculateProgressMessage(latestHistoricGroundFormUpdate)}`}
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel
              value={calculateProgress(latestHistoricGroundFormUpdate)}
            />
          </Box>
        </div>
      )}
      {currentStage === 4 && (
        <div className="ground-display loading-bar">
          {`Recalculating Historic Player Form: ${getLatestPlayerMessage(
            latestHistoricPlayerFormUpdate
          )}: ${calculateProgressMessage(latestHistoricPlayerFormUpdate)}`}
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel
              value={calculateProgress(latestHistoricPlayerFormUpdate)}
            />
          </Box>
        </div>
      )}
    </>
  );
}
